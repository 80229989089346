
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { BIconServer, BIconExclamationCircle, BIconX, BIconCheckCircle, BIconPatchExclamation } from "bootstrap-vue";
import { GlobalNotificationInterface } from "@/interfaces/globalNotification";
export default Vue.extend({
  name: "NotificationsDisplay",
  components: {
    BIconServer,
    BIconExclamationCircle,
    BIconX,
    BIconCheckCircle,
    BIconPatchExclamation,
  },
  methods: {
    ...mapGetters({
      notifications: "notifications/items",
    }),
    ...mapActions({
      dropNotification: "notifications/dropNotificationByIndex",
      logOut: "auth/setUserLoggedOut",
    }),
    formattedText(value: string): string {
      const formattedValue = value.replace("[\"", "").replace("\"]", "");

      const txt = document.createElement("textarea");
      txt.innerHTML = formattedValue;

      const decodedHTML = txt.value;

      txt.remove();

      return decodedHTML;
    },
  },
});
