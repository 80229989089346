import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import Cookies from "js-cookie";
import { combineExpiredSessionRoutes, routerPaths } from "./routerPaths";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: routerPaths.ROOT,
    redirect: routerPaths.NEWS,
  },
  {
    path: routerPaths.NEWS,
    name: "News",
    component: () => import(/* webpackChunkName: "news" */ "@/views/News.vue"),
    meta: { title: "meta.titles.news" },
  },
  {
    path: routerPaths.APPLICATIONS,
    name: "Applications",
    component: () => import(/* webpackChunkName: "applications" */ "@/views/Applications.vue"),
    meta: { title: "meta.titles.applications" },
  },
  {
    path: routerPaths.NEW_APPLICATION,
    name: "NewApplication",
    component: () => import(/* webpackChunkName: "newApplication" */ "@/views/NewApplication.vue"),
    meta: { title: "meta.titles.newApplication" },
  },
  {
    path: routerPaths.EDIT_APPLICATION_STEP2,
    name: "EditApplicationStep2",
    component: () => import(/* webpackChunkName: "editApplication" */ "@/views/steps/NewStep2.vue"),
    meta: { title: "meta.titles.editApplicationStep2" },
  },
  {
    path: routerPaths.NEW_APPLICATION_STEP2,
    name: "NewApplicationStep2",
    component: () => import(/* webpackChunkName: "newApplication" */ "@/views/steps/NewStep2.vue"),
    meta: { title: "meta.titles.newApplicationStep2" },
  },
  {
    path: routerPaths.NEW_APPLICATION_STEP3,
    name: "NewApplicationStep3",
    component: () => import(/* webpackChunkName: "newApplication" */ "@/views/steps/NewStep3.vue"),
    meta: { title: "meta.titles.newApplicationStep3" },
  },
  {
    path: routerPaths.EDIT_APPLICATION_STEP3,
    name: "EditApplicationStep3",
    component: () => import(/* webpackChunkName: "newApplication" */ "@/views/steps/EditStep3.vue"),
    meta: { title: "meta.titles.editApplicationStep3" },
  },
  {
    path: routerPaths.NEW_APPLICATION_STEP4,
    name: "NewApplicationStep4",
    component: () => import(/* webpackChunkName: "newApplication" */ "@/views/steps/NewStep4.vue"),
    meta: { title: "meta.titles.newApplicationStep4" },
  },
  {
    path: routerPaths.NEW_APPLICATION_STEP5,
    name: "NewApplicationStep5",
    component: () => import(/* webpackChunkName: "newApplication" */ "@/views/steps/NewStep5.vue"),
    meta: { title: "meta.titles.newApplicationStep5" },
  },
  {
    path: routerPaths.EDIT_APPLICATION_STEP4,
    name: "EditApplicationStep4",
    component: () => import(/* webpackChunkName: "editApplication" */ "@/views/steps/EditStep4.vue"),
    meta: { title: "meta.titles.editApplicationStep4" },
  },
  {
    path: routerPaths.EDIT_APPLICATION_STEP5,
    name: "EditApplicationStep5",
    component: () => import(/* webpackChunkName: "editApplication" */ "@/views/steps/EditStep5.vue"),
    meta: { title: "meta.titles.editApplicationStep5" },
  },
  {
    path: routerPaths.SEARCH_APPLICATIONS,
    name: "SearchApplications",
    component: () => import(/* webpackChunkName: "searchApplications" */ "@/views/SearchApplications.vue"),
    meta: { title: "meta.titles.searchApplications" },
  },
  {
    path: routerPaths.ACCOUNT_SETTINGS,
    name: "AccountSettings",
    component: () => import(/* webpackChunkName: "accountSettings" */ "@/views/AccountSettings.vue"),
    meta: { title: "meta.titles.accountSettings" },
  },
  {
    path: routerPaths.REPORTS,
    name: "Reports",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/Reports.vue"),
    meta: { title: "meta.titles.reports" },
  },
  {
    path: routerPaths.SPREADSHEET,
    name: "Spreadsheet",
    component: () => import(/* webpackChunkName: "spreadsheet" */ "@/views/Spreadsheet.vue"),
    meta: { title: "meta.titles.spreadsheet" },
  },
  {
    path: routerPaths.ACCOUNTS_LIST,
    name: "AccountsList",
    component: () => import(/* webpackChunkName: "accounts" */ "../views/accounts/AccountsList.vue"),
    meta: { title: "meta.titles.accounts", requiresSupervisor: true },
  },
  {
    path: routerPaths.ACCOUNT_EDIT,
    name: "AccountEdit",
    component: () => import(/* webpackChunkName: "account_edit" */ "../views/accounts/AccountEdit.vue"),
    meta: { title: "meta.titles.accountEdit", requiresSupervisor: true },
  },
  {
    path: routerPaths.ACCOUNT_CREATE,
    name: "AccountCreate",
    component: () => import(/* webpackChunkName: "account_create" */ "../views/accounts/AccountCreate.vue"),
    meta: { title: "meta.titles.accountCreate", requiresSupervisor: true },
  },
  // routes without authentication
  {
    path: routerPaths.LOGIN,
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.login" },
  },
  {
    path: routerPaths.PASSWORD_REMIND,
    name: "PasswordRemind",
    component: () => import(/* webpackChunkName: "login" */ "@/views/PasswordRemind.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.passwordRemind" },
  },
  {
    path: routerPaths.RECOGNIZER,
    name: "Recognizer",
    component: () => import(/* webpackChunkName: "recognizer" */ "@/views/Recognizer.vue"),
    meta: { notRequiresAuth: true, title: "meta.titles.recognizer" },
  },
  {
    path: routerPaths.DEBT_REPORT,
    name: "DebtReport",
    component: () => import(/* webpackChunkName: "debt_report" */ "@/views/DebtReportPreview.vue"),
    meta: { title: "meta.titles.debtReport" },
  },
  {
    path: routerPaths.PAGE_NOT_FOUND,
    name: "PageNotFound",
    component: () => import(/* webpackChunkName: "page_not_found" */ "@/views/PageNotFound.vue"),
    meta: { title: "meta.titles.pageNotFound" },
  },
  {
    path: routerPaths.PERMISSION_DENIED,
    name: "PermissionDenied",
    component: () => import(/* webpackChunkName: "page_not_found" */ "@/views/PageNotFound.vue"),
    props: { denied: true },
    meta: { title: "meta.titles.permissionDenied" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // offload all the guard logic into method which will be called only after initialization of the store
  const routerGuards = () => {
    if (to.meta && !to.meta.notRequiresAuth && !store.getters["auth/isLoggedIn"]) {
      if (to.path.length && to.path !== routerPaths.LOGIN && to.path !== routerPaths.ROOT) {
        Cookies.set("intended_url", to.path);
        if (localStorage.getItem("user")) {
          Cookies.set("intended_role", JSON.parse(localStorage.getItem("user") || "").Role);
        }
      }

      next({ path: routerPaths.LOGIN });

      return;
    }

    if (
      !store.getters["auth/isManager"] &&
      store.getters["auth/sessionExpires"] &&
      store.getters["auth/isSessionExpired"] &&
      !combineExpiredSessionRoutes(store.getters["auth/applicationsInSession"] || 0).includes(to.path as routerPaths)
    ) {
      store.dispatch("notifications/showSessionExpiredError", store.getters["auth/sessionTimeout"]);

      if (from.path !== routerPaths.NEWS) {
        next({ path: routerPaths.NEWS });
      }

      return;
    }

    if (to.meta && to.meta.requiresSupervisor && !store.getters["auth/isSupervisor"]) {
      next({ path: routerPaths.PERMISSION_DENIED });
    }

    next();
  };

  // check if store is already ready
  if (!store.getters["auth/isInitialized"]) {
    store.dispatch("auth/initialize").then(() => {
      routerGuards();
    });

    return;
  }

  routerGuards();
});

router.afterEach(() => {
  // drop notifications when navigating to new place
  store.commit("notifications/resetItems", {});
});

export default router;
