import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueApollo from "vue-apollo";

import i18n from "@/plugins/i18n";
import "./customizations/filters.ts";

import "./plugins/veeValidate";
import apolloProvider from "./plugins/chatApolloProvider";

import "./app.scss";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueApollo);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
