import { TimeRequestParams } from "./timeParams";

class TimeClient {
  /**
   * Sends fetch request and returns json transformed from response XML
   *
   * @param path
   * @param method
   * @param body
   * @param headers?
   *
   * @returns Promise
   */
  // sendRequest(path: string, method: string, bodyParams: any[]): Promise<any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendRequest(path: string, method: string, body: TimeRequestParams | null, headers?: HeadersInit): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let parsedBodyParams = {};
        if (body) {
          // body usually comes in nested format because of typing TimeRequestParams
          // so we need to use only internal piece of it - the value of the first object in body
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const [key, value] of Object.entries(body)) {
            parsedBodyParams = value;
          }
        }

        const requestOptions: RequestInit = {
          method: method,
          body: JSON.stringify(parsedBodyParams),
          redirect: "follow",
          headers: {
            ...{
              // "Content-Type": "multipart/form-data"
              "Content-Type": "application/json",
            },
            ...headers,
          },
        };

        if (method === "GET" || method === "HEAD") {
          delete requestOptions.body;
        }

        const offsetValue = process.env.VUE_APP_SERVER_TIME_OFFSET ? process.env.VUE_APP_SERVER_TIME_OFFSET.toString().toString() : "";

        // seting offset form env params
        fetch(process.env.VUE_APP_MOLECULER_URL + path.replace(":offset", offsetValue), requestOptions)
          .then((response) => response.json())
          .then((result) => {
            result.error ? reject(result) : resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default TimeClient;
