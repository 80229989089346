import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import notifications from "./modules/notifications";
import newApplication from "./modules/newApplication";
import sont from "./modules/sont";
import sellers from "./modules/sellers";

Vue.use(Vuex);

// trying to polyfill require.context to make jest tests happy
if (typeof require.context === "undefined") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const fs = require("fs");
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const path = require("path");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: unreachable code
  require.context = (base = ".", scanSubDirectories = false, regularExpression = /\.js$/) => {
    const files = {};

    function readDirectory(directory: any) {
      fs.readdirSync(directory).forEach((file: any) => {
        const fullPath = path.resolve(directory, file);

        if (fs.statSync(fullPath).isDirectory()) {
          if (scanSubDirectories) readDirectory(fullPath);

          return;
        }

        if (!regularExpression.test(fullPath)) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: unreachable code
        files[fullPath] = true;
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: unreachable code
    readDirectory(path.resolve(__dirname, base));

    function Module(file: string) {
      return require(file);
    }

    Module.keys = () => Object.keys(files);

    return Module;
  };
}

// Load store modules dynamically.
const requireContext = require.context("./modules", false, /.*\.ts$/);

const modules = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.ts$)/g, ""), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }

    return { ...modules, [name]: module };
  }, {});

export default new Vuex.Store({
  modules: {
    auth,
    notifications,
    sont,
    sellers,
    newApplication,
  },
});
