
import Vue from "vue";
import { mapGetters } from "vuex";
import { ChatMessageInterface } from "./chatInterfaces";
export default Vue.extend({
  name: "ChatConversation",
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    chatRoomId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newMessage: "",
    };
  },
  methods: {
    ...mapGetters({
      user: "auth/user",
    }),
    isMine(originalAuthor: string) {
      return originalAuthor == this.user().VardasPavarde;
    },
    formatAuthor(originalAuthor: string) {
      if (this.isMine(originalAuthor)) {
        return "Aš";
      }

      return originalAuthor;
    },
    formatDate(val: string) {
      const d = new Date(val);

      const todayDateSegment = this.convertDateToDateFormat(new Date());
      const messageDateSegment = this.convertDateToDateFormat(d);

      let dateSegment = messageDateSegment;
      if (todayDateSegment === messageDateSegment) {
        dateSegment = "";
      }

      return `${dateSegment}${d.getHours() > 9 ? d.getHours() : "0" + d.getHours()}:${
        d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes()
      }:${d.getSeconds() > 9 ? d.getSeconds() : "0" + d.getSeconds()}`;
    },
    convertDateToDateFormat(date: Date) {
      return `${date.getUTCFullYear()}-${date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()}-${
        date.getDay() > 9 ? date.getDay() : "0" + date.getDay()
      }`;
    },
    keyDownHandler(event: KeyboardEvent) {
      if (!event.shiftKey && event.key === "Enter") {
        event.preventDefault();
        this.$emit("send-message", { message: this.newMessage, room: this.getChatRoomId() });
        this.newMessage = "";
      }
    },
    getAuthorsList(messagesLists: ChatMessageInterface[]): string[] {
      return messagesLists
        .map((item: ChatMessageInterface) => item.author)
        .filter((value, index, self) => value.length && self.indexOf(value) === index && value !== this.user().VardasPavarde);
    },
    getChatRoomId() {
      if (this.chatRoomId.length) {
        return this.chatRoomId;
      }
    },
  },
  computed: {
    switchInput(): boolean {
      if (this.newMessage.length > 51 || this.newMessage.includes("\n")) {
        return true;
      }

      return false;
    },
  },
});
