
import Vue from "vue";
import ChatSettings from "./chatSettings.vue";
import ChatChannels from "./chatChannels.vue";
import ChatConversation from "./chatConversation.vue";
import gql from "graphql-tag";
import { mapGetters } from "vuex";

// const ROOMS_QUERY = gql`
//   query MyQuery {
//     room {
//       id
//       third_party_id
//       messages {
//         author
//         chat_room_id
//         created_at
//         id
//         is_from_app
//         text
//         updated_at
//       }
//     }
//   }
// `;

const SUBSCRIPTION_ROOMS = gql`
  subscription MyQuery {
    room {
      id
      third_party_id
      messages {
        author
        chat_room_id
        created_at
        id
        is_from_app
        text
        updated_at
      }
    }
  }
`;

const NEW_MESSAGE_MUTTION = gql`
  mutation ($message: message_insert_input!) {
    insert_message_one(object: $message) {
      author
      text
      is_from_app
      chat_room_id
    }
  }
`;

const NEW_ROOM_MUTTION = gql`
  mutation ($room: room_insert_input!) {
    insert_room_one(object: $room) {
      third_party_id
    }
  }
`;

export default Vue.extend({
  name: "ChatContainer",
  components: {
    ChatSettings,
    ChatChannels,
    ChatConversation,
  },
  props: {
    unreadMessages: {
      type: Number,
      default: 0,
    },
    channels: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      chatVisible: false,
      chatCollapseOpening: false,
      chatCollapseShown: false,
      settingsVisible: false,
      settingsCollapseOpening: false,
      settingsCollapseShown: false,
      chatStatus: { state: "online", message: "" },
      oldStatus: "",
      positions: {
        clientX: undefined as number | undefined,
        clientY: undefined as number | undefined,
        movementX: 0,
        movementY: 0,
      },
      currentChatChannels: [] as any[],
      roomFilter: "",
      apolloClient: null as null | any,
      loading: 0,
      chatMessages: [] as [] | any,
      room: [],
    };
  },
  apollo: {
    // room: {
    //   query: ROOMS_QUERY,
    //   loadingKey: "loading"
    //   // manual: true,
    //   // result(event: { data: any; loading: boolean; network: number; stale: boolean }) {
    //   //   if (event && !event.loading) {
    //   //     this.currentChatChannels = this.filterChatRooms(event.data.room);
    //   //   }
    //   // }
    // },
    $subscribe: {
      room: {
        query: SUBSCRIPTION_ROOMS,
        result(data: any) {
          this.room = data.data.room;
        },
      },
    },
  },
  methods: {
    ...mapGetters({
      user: "auth/user",
      application: "newApplication/application",
    }),
    submitMessage(content: { message: string; room: string }) {
      this.$apollo.mutate({
        mutation: NEW_MESSAGE_MUTTION,
        variables: {
          message: {
            author: this.user().VardasPavarde,
            text: content.message,
            is_from_app: true,
            chat_room_id: content.room,
          },
        },
      });
    },
    filterChatRooms(rooms: any[]): object[] {
      if (this.roomFilter && this.roomFilter != "") {
        return rooms.filter((item: { third_party_id: string | number }) => item.third_party_id == this.roomFilter) as object[];
      }

      return rooms as object[];
    },
    openRoom(thirdPartyId: string): void {
      this.roomFilter = thirdPartyId;
      this.currentChatChannels = this.filterChatRooms(this.room);
    },
    openNewRoom(thirdPartyId: string) {
      // create new chat room
      this.$apollo.mutate({
        mutation: NEW_ROOM_MUTTION,
        variables: {
          room: {
            third_party_id: thirdPartyId,
          },
        },
      });
      this.openRoom(thirdPartyId);
    },
    collapseChat() {
      this.chatVisible = !this.chatVisible;
    },
    collapseSettings() {
      this.settingsVisible = !this.settingsVisible;
    },
    chatCollapseHidden() {
      this.chatCollapseShown = false;

      if (this.settingsCollapseOpening) {
        this.$nextTick(() => {
          this.settingsCollapseOpening = false;
          this.settingsVisible = !this.settingsVisible;
        });
      }
    },
    settingsCollapseHidden() {
      this.settingsCollapseShown = false;

      if (this.chatCollapseOpening) {
        this.$nextTick(() => {
          this.chatCollapseOpening = false;
          this.chatVisible = !this.chatVisible;
        });
      }
    },
    toggleStatus() {
      /**
       * offline -> depending on old state: online | invisible | busy
       * online -> offline
       * invisible -> offline
       * busy -> offline
       */
      switch (this.chatStatus.state) {
        case "offline":
          if (this.oldStatus.length) {
            this.saveState(this.oldStatus);
          } else {
            this.saveState("online");
          }
          this.oldStatus = "offline";
          this.goOnline();
          break;
        case "online":
          this.oldStatus = "online";
          this.saveState("offline");
          this.goOffline();
          break;
        default:
          this.oldStatus = this.chatStatus.state;
          this.saveState("offline");
          this.goOffline();
          break;
      }
    },
    saveStateWithHistory(val: string) {
      this.oldStatus = this.chatStatus.state;
      this.saveState(val);

      if (val == "offline") {
        this.goOffline();
      } else {
        this.goOnline();
      }
    },
    saveState(val: string) {
      this.chatStatus = { ...this.chatStatus, ...{ state: val } };
    },
    saveMessage(val: string) {
      this.chatStatus = { ...this.chatStatus, ...{ message: val } };
    },
    goOffline() {
      this.currentChatChannels = [];
      this.$apollo.subscriptions.room.skip = true;
    },
    goOnline() {
      this.currentChatChannels = this.filterChatRooms(this.room);
      this.$apollo.subscriptions.room.skip = false;
    },
    dragMouseDown: function (event: MouseEvent) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event: MouseEvent) {
      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: unreachable code
      this.positions.movementX = this.positions.clientX - event.clientX;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: unreachable code
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      if (this.$refs.draggableContainer) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: unreachable code
        this.$refs.draggableContainer.style.top = this.$refs.draggableContainer.offsetTop - this.positions.movementY + "px";
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: unreachable code
        this.$refs.draggableContainer.style.left = this.$refs.draggableContainer.offsetLeft - this.positions.movementX + "px";
      }
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
  computed: {
    computedUnreadMessages(): string {
      return this.unreadMessages >= 100 ? "99+" : this.unreadMessages.toString();
    },
    computedVariantByState(): string {
      let result = "secondary";
      if (this.chatStatus) {
        switch (this.chatStatus.state) {
          case "online":
            result = "success";
            break;
          case "busy":
            result = "danger";
            break;
          case "invisible":
          case "offline":
            result = "secondary";
            break;
        }
      }

      return result;
    },
  },
  watch: {
    room: function (val) {
      if (val && val.length) {
        this.currentChatChannels = this.filterChatRooms(val);
      }
    },
  },
});
