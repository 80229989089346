
import router from "@/router";
import { routerPaths } from "@/router/routerPaths";
import Vue from "vue";
import { mapGetters } from "vuex";
import { ChatRoomInterface } from "./chatInterfaces";
export default Vue.extend({
  name: "ChatChannels",
  props: {
    channels: Array,
  },
  data() {
    return {
      forceRefresh: 0,
    };
  },
  methods: {
    ...mapGetters({
      application: "newApplication/application",
    }),
    chatByApplicationExists(channels: ChatRoomInterface[]) {
      // list of routes where we might want to start new chat
      let chatFirstRoutes: string[] = [
        routerPaths.EDIT_APPLICATION_STEP2,
        routerPaths.EDIT_APPLICATION_STEP3,
        routerPaths.EDIT_APPLICATION_STEP4,
        routerPaths.EDIT_APPLICATION_STEP5,
        routerPaths.NEW_APPLICATION,
        routerPaths.NEW_APPLICATION_STEP2,
        routerPaths.NEW_APPLICATION_STEP3,
        routerPaths.NEW_APPLICATION_STEP4,
        routerPaths.NEW_APPLICATION_STEP5,
      ];

      if (router.currentRoute.params?.id?.length) {
        chatFirstRoutes = chatFirstRoutes.map((item) => item.replace(":id", router.currentRoute.params.id));
      }

      if (
        chatFirstRoutes.indexOf(router.currentRoute.path) !== -1 &&
        this.application().ParaiskosID &&
        !channels.filter((item) => item.third_party_id === this.application().ParaiskosID).length
      ) {
        return this.application().ParaiskosID;
      }

      return false;
    },
  },
  computed: {
    currentApplicationId(): string {
      return router.currentRoute.params?.id || this.application().ParaiskosID;
    },
  },
  watch: {
    $route() {
      this.forceRefresh++;
    },
  },
});
