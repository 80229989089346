export enum routerPaths {
  ROOT = "/",
  LOGIN = "/login",
  PASSWORD_REMIND = "/password-remind",
  LOGOUT = "/logout",
  NEWS = "/news",
  APPLICATIONS = "/applications",
  NEW_APPLICATION = "/applications/add",
  EDIT_APPLICATION_STEP2 = "/applications/add/2/:id",
  NEW_APPLICATION_STEP2 = "/applications/add/2",
  NEW_APPLICATION_STEP3 = "/applications/add/3",
  NEW_APPLICATION_STEP4 = "/applications/add/4",
  NEW_APPLICATION_STEP5 = "/applications/add/5",
  EDIT_APPLICATION_STEP3 = "/applications/edit/:id/3",
  EDIT_APPLICATION_STEP4 = "/applications/edit/:id/4",
  EDIT_APPLICATION_STEP5 = "/applications/edit/:id/5",
  SEARCH_APPLICATIONS = "/search",
  ACCOUNT_SETTINGS = "/settings",
  REPORTS = "/reports",
  SPREADSHEET = "/spreadsheet",
  ACCOUNTS_LIST = "/supervisor/accounts",
  ACCOUNT_EDIT = "/supervisor/accounts/edit/:id",
  ACCOUNT_CREATE = "/supervisor/accounts/create",
  RECOGNIZER = "/recognizer/:type/:hash",
  DEBT_REPORT = "/reports/debt-report/:userId/:id",
  PAGE_NOT_FOUND = "*",
  PERMISSION_DENIED = "/permission-denied",
}

export const expiredSessionRoutes: Array<routerPaths> = [
  routerPaths.NEWS,
  routerPaths.NEW_APPLICATION,
  routerPaths.NEW_APPLICATION_STEP2,
  routerPaths.NEW_APPLICATION_STEP3,
  routerPaths.NEW_APPLICATION_STEP4,
  routerPaths.NEW_APPLICATION_STEP5,
  routerPaths.SPREADSHEET,
  routerPaths.LOGIN,
];

export const expiredSessionRoutesWithParams = [
  routerPaths.EDIT_APPLICATION_STEP2,
  routerPaths.EDIT_APPLICATION_STEP3,
  routerPaths.EDIT_APPLICATION_STEP4,
  routerPaths.EDIT_APPLICATION_STEP5,
];

export function combineExpiredSessionRoutes(applicationId: number[]) {
  let routesInSession: string[] = [];
  applicationId.forEach((applicaionInSession) => {
    routesInSession = [
      ...routesInSession,
      ...expiredSessionRoutesWithParams.map((item) => item.replaceAll(":id", applicaionInSession.toString())),
    ];
  });

  return [...expiredSessionRoutes, ...routesInSession];
}
