import { ApplicationInterface, GoodsInterface, PrekeInterface } from "@/interfaces/application";
import { ApplicationSettingsInterface, ContractStatuses, ContractTypes, ProposedOptionInterface } from "@/interfaces/applicationSettings";
import { BuyerInterface, BuyerSpouseInterface } from "@/interfaces/buyer";
import { SellerInterface } from "@/interfaces/seller";
import { ActionContext, Module } from "vuex";

// state

export const state = {
  seller: null,
  buyer: null,
  buyerSpouse: null,
  application: null,
  applicationSettings: null,
  proposedOptions: [],
  goods: [],
  applicationStep: 0,
  pendingState: null, // this will be used to initiate something after full reset to defaults,
  buyerSpouseDataBackup: null,
  keepProposedOptions: false,
  clientDataUpdated: null,
  spouseDataUpdated: null,
};

// getters
export const getters = {
  clientDataUpdated: (state: { clientDataUpdated: boolean }) => state.clientDataUpdated,
  spouseDataUpdated: (state: { spouseDataUpdated: boolean }) => state.spouseDataUpdated,
  seller: (state: { seller: SellerInterface }) => state.seller,
  buyer: (state: { buyer: BuyerInterface }) => state.buyer,
  buyerSpouse: (state: { buyerSpouse: BuyerSpouseInterface }) => state.buyerSpouse,
  buyerSpouseDataBackup: (state: { buyerSpouseDataBackup: any }) => state.buyerSpouseDataBackup,
  application: (state: { application: ApplicationInterface }) => state.application,
  applicationSettings: (state: { applicationSettings: ApplicationSettingsInterface }) => state.applicationSettings,
  proposedOptions: (state: { proposedOptions: ProposedOptionInterface[] }) => state.proposedOptions,
  goods: (state: { goods: GoodsInterface[] }) => state.goods,
  applicationStep: (state: { applicationStep: number }) => state.applicationStep,
  isContract: (state: { application: ApplicationInterface }) => {
    if (!state.application || !state.application?.Busena) {
      return false;
    }

    // @todo: try to refactor magic numbers
    return [1, "1", 2, "2", 3, "3", 7, "7"].indexOf(state.application.Busena) !== -1;
  },
  isSignedContract: (state: { application: ApplicationInterface }) => {
    if (!state.application || !state.application?.Busena) {
      return false;
    }

    return [ContractStatuses.SIGNED].indexOf(+state.application.Busena) !== -1;
  },
  isSignInitiationPossible: (state: { application: ApplicationInterface }) => {
    if (!state.application || !state.application?.InicijavimasGalimas) {
      return false;
    }

    return +state.application.InicijavimasGalimas === 1;
  },
  isGoodsContract: (state: { application: { SutartiesTipas: number } }) => {
    if (state.application && [ContractTypes.GOODS, ContractTypes.CREDIT].indexOf(+state.application.SutartiesTipas) !== -1) {
      return true;
    }

    return false;
  },
  isCreditContract: (state: { application: { SutartiesTipas: ContractTypes } }) => {
    if (state.application && +state.application.SutartiesTipas == ContractTypes.CREDIT) {
      return true;
    }

    return false;
  },
  isArchive: (state: { application: { Busena: ContractStatuses } }) => {
    if (state.application && state.application.Busena == ContractStatuses.ARCHIVE) {
      return true;
    }

    return false;
  },
  pendingState: (state: { pendingState: any }) => {
    return state.pendingState;
  },
  shouldKeepProposedOptions: (state: { keepProposedOptions: boolean }) => {
    return state.keepProposedOptions;
  },
};

// mutations
export const mutations = {
  setClientDataUpdated(state: { clientDataUpdated: boolean }, clientDataUpdated: boolean) {
    state.clientDataUpdated = clientDataUpdated;
  },
  setSpouseDataUpdated(state: { spouseDataUpdated: boolean }, spouseDataUpdated: boolean) {
    state.spouseDataUpdated = spouseDataUpdated;
  },
  setSeller(state: { seller?: SellerInterface }, seller: SellerInterface) {
    state.seller = seller;
  },
  setBuyer(state: { buyer?: BuyerInterface }, buyer: BuyerInterface) {
    state.buyer = buyer;
  },
  setBuyerSpouse(state: { buyerSpouse?: BuyerInterface }, buyerSpouse: BuyerInterface) {
    state.buyerSpouse = buyerSpouse;
  },
  setBuyerSpouseDataBackup(state: { buyerSpouseDataBackup?: any }, buyerSpouseDataBackup: any) {
    state.buyerSpouseDataBackup = buyerSpouseDataBackup;
  },
  setApplication(state: { application?: ApplicationInterface }, application: ApplicationInterface) {
    state.application = application;
  },
  setApplicationSettings(state: { applicationSettings?: ApplicationSettingsInterface }, applicationSettings: ApplicationSettingsInterface) {
    state.applicationSettings = applicationSettings;
  },
  setProposedOptions(state: { proposedOptions?: ProposedOptionInterface[] }, proposedOptions: ProposedOptionInterface[]) {
    state.proposedOptions = proposedOptions;
  },
  setGoods(state: { goods: GoodsInterface[] }, goods: GoodsInterface[]) {
    state.goods = goods;
  },
  setPendingState(state: { pendingState: any }, pendingState: any) {
    state.pendingState = pendingState;
  },
  setApplicationStep(state: { applicationStep: number }, applicationStep: number) {
    state.applicationStep = applicationStep;
  },
  applyPendingState(state: { pendingState: { application: any }; application: any }) {
    if (!state.pendingState) {
      return;
    }

    if (state.pendingState.application) {
      state.application = { ...state.application, ...state.pendingState.application };

      delete state.pendingState.application;
    }
    // @todo: make some changes to other state stuff if required
  },
  setKeepProposedOptions(state: { keepProposedOptions: boolean }, newState: boolean) {
    state.keepProposedOptions = newState;
  },
};

// actions
export const actions = {
  setClientDataUpdated({ commit }: ActionContext<any, any>, payload: boolean) {
    commit("setClientDataUpdated", payload);
  },
  setSpouseDataUpdated({ commit }: ActionContext<any, any>, payload: boolean) {
    commit("setSpouseDataUpdated", payload);
  },
  resetStore({ commit, dispatch }: ActionContext<any, any>) {
    commit("setSeller", null);
    dispatch("resetForNewApplication");
  },
  resetForNewApplication({ commit }: ActionContext<any, any>) {
    commit("setBuyer", null);
    commit("setBuyerSpouse", null);
    commit("setApplication", null);
    commit("setApplicationSettings", null);
    commit("setProposedOptions", []);
    commit("setGoods", []);
  },
  storeSeller({ commit }: ActionContext<any, any>, payload?: SellerInterface) {
    commit("setSeller", payload);
  },
  storeBuyer({ commit }: ActionContext<any, any>, payload?: BuyerInterface) {
    commit("setBuyer", payload);
  },
  patchBuyer({ commit, state }: ActionContext<any, any>, payload?: BuyerInterface) {
    commit("setBuyer", { ...state.buyer, ...payload });
    commit("setApplication", { ...state.application, ...{ ...state.buyer, ...payload } });
  },
  storeBuyerSpouse({ commit }: ActionContext<any, any>, payload?: BuyerInterface) {
    commit("setBuyerSpouse", payload);
  },
  storeBuyerSpouseDataBackup({ commit }: ActionContext<any, any>, payload?: any) {
    commit("setBuyerSpouseDataBackup", payload);
  },
  patchBuyerSpouse({ commit, state }: ActionContext<any, any>, payload?: BuyerInterface) {
    commit("setBuyerSpouse", { ...state.buyerSpouse, ...payload });
    commit("setApplication", { ...state.application, ...{ ...state.buyerSpouse, ...payload } });
  },
  storeApplication({ commit }: ActionContext<any, any>, payload?: ApplicationInterface) {
    commit("setApplication", payload);
  },
  storeApplicationSettings({ commit }: ActionContext<any, any>, payload?: ApplicationSettingsInterface) {
    commit("setApplicationSettings", payload);
  },
  patchApplication({ commit, state }: ActionContext<any, any>, payload?: ApplicationInterface) {
    commit("setApplication", { ...state.application, ...payload });
  },
  storeProposedOptions({ commit }: ActionContext<any, any>, payload?: ProposedOptionInterface[] | ProposedOptionInterface) {
    let adoptedPayload = [];
    if (Array.isArray(payload)) {
      adoptedPayload = payload;
    } else {
      adoptedPayload.push(payload);
    }

    // sort proposed options by duration
    adoptedPayload = adoptedPayload.sort((a, b) => {
      return a && b ? a.Trukme - b.Trukme : 0;
    });

    commit("setProposedOptions", adoptedPayload);
  },
  storeGoods({ commit }: ActionContext<any, any>, payload?: GoodsInterface[] | GoodsInterface) {
    let adoptedPayload = [];
    if (Array.isArray(payload)) {
      adoptedPayload = payload;
    } else {
      adoptedPayload.push(payload);
    }

    commit("setGoods", adoptedPayload);
  },
  appendGoods({ commit }: ActionContext<any, any>, payload?: GoodsInterface[] | GoodsInterface) {
    let adoptedPayload = [];
    if (Array.isArray(payload)) {
      adoptedPayload = payload;
    } else {
      adoptedPayload.push(payload);
    }

    commit("setGoods", [...state.goods, ...adoptedPayload]);
  },
  loadApplication({ commit }: ActionContext<any, any>, payload?: ApplicationInterface) {
    commit("setApplication", payload);

    const buyer: BuyerInterface = {
      Vardas: payload?.Vardas,
      Pavarde: payload?.Pavarde,
      AsmensKodas: payload?.AsmensKodas,
      BusimosPajamos: payload?.BusimosPajamos,
      Darboviete: payload?.Darboviete,
      DuomenuPolitikosGavimas: payload?.DuomenuPolitikosGavimas,
      ElPastas: payload?.ElPastas,
      MazesPajamos: payload?.MazesPajamos,
      Pajamos: payload?.Pajamos,
      PajamuPazyma: payload?.PajamuPazyma,
      PajamuTipas: payload?.PajamuTipas,
      Telefonas2: payload?.Telefonas2,
    };

    commit("setBuyer", buyer);

    const buyerSpouse: BuyerSpouseInterface = {
      SutuoktinioTelefonas: payload?.SutuoktinioTelefonas,
      SutuoktinioVardas: payload?.SutuoktinioVardas,
      SutuoktinioPavarde: payload?.SutuoktinioPavarde,
      SutuoktinioAsmensKodas: payload?.SutuoktinioAsmensKodas,
      SutuoktinioElPastas: payload?.SutuoktinioElPastas,
      SutuoktinioPajamos: payload?.SutuoktinioPajamos,
      SutuoktinioDarboviete: payload?.SutuoktinioDarboviete,
      SutuoktinioPajamuTipas: payload?.SutuoktinioPajamuTipas,
      SutuoktinioPajamuPazyma: payload?.SutuoktinioPajamuPazyma,
      SutuoktinioMazesPajamos: payload?.SutuoktinioMazesPajamos,
      SutuoktinioBusimosPajamos: payload?.SutuoktinioBusimosPajamos,
      SutuoktinioDuomenuPolitikosGavimas: payload?.DuomenuPolitikosGavimas,
    };

    commit("setBuyerSpouse", payload?.Sutuoktinis && parseInt(payload?.Sutuoktinis.toString()) > 0 ? buyerSpouse : {});

    const goodsResult: GoodsInterface[] = [];
    const goodsFromPayload = payload?.Prekes?.Preke;
    if (goodsFromPayload && !Array.isArray(goodsFromPayload) && Object.keys(goodsFromPayload).length) {
      // looks like only one item is here
      goodsResult.push({
        title: goodsFromPayload?.Pavadinimas,
        serialNo: goodsFromPayload?.SerijosNr,
        group: goodsFromPayload?.TurtoRusiesID,
        price: goodsFromPayload?.Kaina,
        quantity: goodsFromPayload?.Kiekis,
        insured: goodsFromPayload && goodsFromPayload.Apdrausta > 0,
        amount: goodsFromPayload?.Kiekis,
        warrantySelected: goodsFromPayload?.GarantijosTrukme,
        warranty: goodsFromPayload?.GamintojoGarantija,
        warrantySum: goodsFromPayload?.GarantijosKaina,
      } as GoodsInterface);
    } else if (Array.isArray(goodsFromPayload)) {
      goodsFromPayload.forEach((element: PrekeInterface) =>
        goodsResult.push({
          title: element.Pavadinimas,
          serialNo: element.SerijosNr,
          group: element.TurtoRusiesID,
          price: element.Kaina,
          quantity: element.Kiekis,
          insured: element.Apdrausta > 0,
          amount: element.Kiekis,
          warrantySelected: element?.GarantijosTrukme,
          warranty: element?.GamintojoGarantija,
          warrantySum: element?.GarantijosKaina,
        } as GoodsInterface)
      );
    }

    commit("setGoods", goodsResult);

    commit("setApplicationSettings", { creditType: payload?.Sutuoktinis ? +payload?.Sutuoktinis : 1 });
    if (!state.keepProposedOptions) {
      commit("setProposedOptions", []);
    }
  },
  storePendingState({ commit }: ActionContext<any, any>, payload?: any) {
    commit("setPendingState", payload);
  },
  applyPendingState({ commit }: ActionContext<any, any>) {
    commit("applyPendingState");
  },
  storeApplicationStep({ commit }: ActionContext<typeof state, any>, payload?: number) {
    commit("setApplicationStep", payload);
  },
  storeKeepProposedOptions({ commit }: ActionContext<typeof state, any>, payload: boolean) {
    commit("setKeepProposedOptions", payload);
  },
};

const newApplicationsModule: Module<any, any> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default newApplicationsModule;
