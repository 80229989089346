export const validationMessagesEn = {
  _default: (field: string) => `The ${field} value is not valid`,
  after: (field: string, [target, inclusion]: any) => `The ${field} must be after ${inclusion ? "or equal to " : ""}${target}`,
  alpha: (field: string) => `The ${field} field may only contain alphabetic characters`,
  alpha_dash: (field: string) => `The ${field} field may contain alpha-numeric characters as well as dashes and underscores`,
  alpha_num: (field: string) => `The ${field} field may only contain alpha-numeric characters`,
  alpha_spaces: (field: string) => `The ${field} field may only contain alphabetic characters as well as spaces`,
  before: (field: string, [target, inclusion]: any) => `The ${field} must be before ${inclusion ? "or equal to " : ""}${target}`,
  between: (field: string, [min, max]: any) => `The ${field} field must be between ${min} and ${max}`,
  confirmed: (field: string) => `The ${field} confirmation does not match`,
  credit_card: (field: string) => `The ${field} field is invalid`,
  date_between: (field: string, [min, max]: any) => `The ${field} must be between ${min} and ${max}`,
  date_format: (field: string, [format]: string) => `The ${field} must be in the format ${format}`,
  decimal: (field: string, [decimals = "*"] = []) =>
    `The ${field} field must be numeric and may contain${!decimals || decimals === "*" ? "" : " " + decimals} decimal points`,
  digits: (field: string, [length]: string) => `The ${field} field must be numeric and contains exactly ${length} digits`,
  dimensions: (field: string, [width, height]: any) => `The ${field} field must be ${width} pixels by ${height} pixels`,
  email: (field: string) => `The ${field} field must be a valid email`,
  excluded: (field: string) => `The ${field} field must be a valid value`,
  ext: (field: string) => `The ${field} field must be a valid file`,
  image: (field: string) => `The ${field} field must be an image`,
  included: (field: string) => `The ${field} field must be a valid value`,
  integer: (field: string) => `The ${field} field must be an integer`,
  ip: (field: string) => `The ${field} field must be a valid ip address`,
  ip_or_fqdn: (field: string) => `The ${field} field must be a valid ip address or FQDN`,
  length: (field: string, [length, max]: any) => {
    if (max) {
      return `The ${field} length must be between ${length} and ${max}`;
    }

    return `The ${field} length must be ${length}`;
  },
  max: (field: string, [length]: string) => `The ${field} field may not be greater than ${length} characters`,
  max_value: (field: string, [max]: any) => `The ${field} field must be ${max} or less`,
  mimes: (field: string) => `The ${field} field must have a valid file type`,
  min: (field: string, [length]: string) => `The ${field} field must be at least ${length} characters`,
  min_value: (field: string, [min]: any) => `The ${field} field must be ${min} or more`,
  numeric: (field: string) => `The ${field} field may only contain numeric characters`,
  regex: (field: string) => `The ${field} field format is invalid`,
  required: (field: string) => `The ${field} field is required`,
  required_if: (field: string, [target]: string) => `The ${field} field is required when the ${target} field has this value`,
  size: (field: string, [size]: any) => `The ${field} size must be less than ${size}`,
  url: (field: string) => `The ${field} field is not a valid URL`,
  inList: (field: string) => `Field ${field} is required`,
};
