import TimeClient from "@/lib/time/timeClient";
import { timeActions } from "@/lib/time/timeParams";
import router from "@/router";
import { routerPaths } from "@/router/routerPaths";
import Cookies from "js-cookie";
import { ActionContext, Module, ActionTree } from "vuex";

// state
export const state = {
  initialized: false,
  user: null,
  isLoggedIn: false,
  locale: "lt",
  isManager: false,
  isUser: false,
  isSupervisor: false,
  isSeller: false,
  sessionExpires: null,
  sessionTimeout: 0,
  sessionExpired: false,
  applicationsInSession: [],
};

// getters
export const getters = {
  isInitialized: (state: { initialized: boolean }) => state.initialized,
  user: (state: { user: unknown }) => state.user,
  isLoggedIn: (state: { isLoggedIn: boolean }) => state.isLoggedIn,
  isManager: (state: { isManager: boolean }) => state.isManager,
  isSupervisor: (state: { isSupervisor: boolean }) => state.isSupervisor,
  isUser: (state: { isUser: boolean }) => state.isUser,
  isSeller: (state: { isSeller: boolean }) => state.isSeller,
  locale: (state: { locale: string }) => state.locale,
  canInsure: (state: { user: { DraudimasGalimas: boolean } }): boolean => +state.user?.DraudimasGalimas === 1,
  sessionExpires: (state: { sessionExpires: number }) => state.sessionExpires,
  sessionTimeout: (state: { sessionTimeout: number }) => state.sessionTimeout,
  isSessionExpired: (state: { sessionExpired: boolean }) => state.sessionExpired,
  applicationsInSession: (state: { applicationsInSession: number[] }) => state.applicationsInSession,
};

// mutations
export const mutations = {
  initialize(state: { initialized: boolean }, payload: boolean) {
    state.initialized = payload;
  },
  userLogin(
    state: {
      user: unknown;
      isLoggedIn: boolean;
      isManager: boolean;
      isSupervisor: boolean;
      isUser: boolean;
      isSeller: boolean;
    },
    { user }: unknown
  ) {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
    state.isLoggedIn = true;
    state.isUser = true;

    switch (user.Role) {
      case 1: // ROLE_MANAGER
      case "1":
        state.isManager = true;
        break;
      case 3: // ROLE_SUPERVISOR
      case "3":
        state.isSupervisor = true;
        state.isSeller = true;
        break;
      case 0: // ROLE_USER
      case "0":
      default:
        state.isSeller = true;
        break;
    }

    // this tmp cookie might be set by the router before redirect to login
    // and we can use this value to redirect user back where he wanted after successfully login
    const intendedUrl = Cookies.get("intended_url");

    if (intendedUrl && intendedUrl !== routerPaths.LOGIN) {
      const intendedRole = Cookies.get("intended_role");
      Cookies.remove("intended_url");
      Cookies.remove("intended_role");
      if (intendedRole && user.Role == intendedRole) {
        router.push({ path: intendedUrl });

        return;
      }
    }

    if (router.currentRoute.path === routerPaths.LOGIN) {
      router.push({ path: routerPaths.NEWS });
    }
  },
  userLogout(state: {
    user: { Role: string | object } | null;
    isLoggedIn: boolean;
    locale: string;
    isManager: boolean;
    isUser: boolean;
    isSupervisor: boolean;
    isSeller: boolean;
    sessionExpired: boolean;
    sessionExpires: number | null;
  }) {
    Cookies.set("intended_role", state.user?.Role || "");
    state.user = null;
    state.isLoggedIn = false;
    state.isLoggedIn = false;
    state.locale = "lt";
    state.isManager = false;
    state.isUser = false;
    state.isSupervisor = false;
    state.isSeller = false;
    localStorage.removeItem("user");

    state.sessionExpires = null;
    localStorage.removeItem("sessionExpires");
    state.sessionExpired = false;

    if (router.currentRoute.path !== routerPaths.LOGIN) {
      Cookies.set("intended_url", router.currentRoute.path);
    }

    sessionStorage.clear();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    router.push({ path: routerPaths.LOGIN }).catch(() => {});
  },
  setLocale(state: { locale: string }, locale: string) {
    state.locale = locale;
    localStorage.setItem("locale", locale);
  },
  setSessionExpires(state: { sessionExpires: number }, sessionExpires: number) {
    state.sessionExpires = sessionExpires;
    localStorage.setItem("sessionExpires", String(sessionExpires));
  },
  setSessionTimeout(state: { sessionTimeout: number }, newSessionTimeout: number) {
    state.sessionTimeout = newSessionTimeout;
  },
  setSessionExpired(state: { sessionExpired: boolean }, expiration: boolean) {
    state.sessionExpired = expiration;
  },
  setApplicationsInSession(state: { applicationsInSession: number[] }, applicationsInSession: number[]) {
    state.applicationsInSession = applicationsInSession.length
      ? [...new Set([...state.applicationsInSession, ...applicationsInSession])]
      : [];
    localStorage.setItem("applicationsInSession", JSON.stringify(state.applicationsInSession));
  },
};

// actions
export const actions: ActionTree<typeof state, any> = {
  initialize({ commit, dispatch }: ActionContext<typeof state, any>) {
    const sessionTimeout = parseInt(process.env.VUE_APP_SELLER_SESSION_TIMEOUT || "") || 30 * 60000;
    dispatch("setSessionTimeout", sessionTimeout);

    return dispatch("loadUserFromStorage").then((user) => {
      if (user && user.user?.userName) {
        dispatch("setUserLoggedIn", user);

        const sessionExpiresStored = localStorage.getItem("sessionExpires");
        if (sessionExpiresStored) {
          // try to load in applications that were craeted withing expired session
          const applicationsInSessionStored = localStorage.getItem("applicationsInSession");
          if (applicationsInSessionStored?.length) {
            commit("setApplicationsInSession", JSON.parse(applicationsInSessionStored));
          }

          return dispatch("extendSession", +sessionExpiresStored).then(() => {
            return new TimeClient()
              .sendRequest(timeActions.timeNow, "GET", null)
              .then((res) => {
                if (state.isLoggedIn && !state.isManager && +(state.sessionExpires || 0) <= +res) {
                  dispatch("notifications/showSessionExpiredError", sessionTimeout, { root: true });
                  commit("setSessionExpired", true);
                  commit("initialize", true);
                }
              })
              .catch((error) => {
                console.log("time check error", error);
              });
          });
        } else {
          return dispatch("extendSession").then(() => {
            commit("initialize", true);
          });
        }
      } else {
        commit("initialize", true);
      }
    });
  },
  loadUserFromStorage() {
    if (localStorage.getItem("user")) {
      return { user: JSON.parse(localStorage.getItem("user") || "") };
    }

    return {};
  },
  setUserLoggedIn({ commit }: ActionContext<typeof state, any>, payload: unknown) {
    commit("userLogin", payload);
  },
  setUserLoggedOut({ commit, dispatch }: ActionContext<typeof state, any>) {
    commit("userLogout");
    dispatch("sont/resetStore", {}, { root: true });
    dispatch("newApplication/resetStore", {}, { root: true });
    dispatch("sellers/resetStore", {}, { root: true });
    dispatch("notifications/dropAll", {}, { root: true });
    dispatch("notifications/dropNotificationByIndex", 0, { root: true });
    localStorage.removeItem("user");
    localStorage.removeItem("sessionExpires");
    commit("setApplicationsInSession", []);
    localStorage.removeItem("applicationsInSession");
  },
  storeLocale({ commit }: ActionContext<typeof state, any>, payload: string) {
    commit("setLocale", payload);
  },
  setSessionTimeout({ commit }: ActionContext<typeof state, any>, payload: number) {
    commit("setSessionTimeout", payload);
  },
  extendSession({ commit }: ActionContext<typeof state, any>, payload: null | number | string = null) {
    if (payload) {
      commit("setSessionExpires", +payload);
      return;
    }

    new TimeClient()
      .sendRequest(timeActions.timeNow, "GET", null)
      .then((res) => {
        commit("setSessionExpires", +res + state.sessionTimeout);
      })
      .catch((error) => {
        console.log("time check error", error);
      });
  },
  setApplicationsInSession({ commit }: ActionContext<typeof state, any>, payload: number | number[]) {
    commit("setApplicationsInSession", Array.isArray(payload) ? payload : [payload]);
  },
  setSessionExpired({ commit }, payload: boolean) {
    commit("setSessionExpired", payload);
  },
};

const authModule: Module<any, any> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default authModule;
