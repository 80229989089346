import { LocaleMessageObject } from "vue-i18n";

const translations: Record<string, LocaleMessageObject> = {
  lt: {
    meta: {
      titles: {
        login: "Prisijungimas",
        applications: "Paraiškos ir sutartys",
        news: "Naujienos",
        newApplication: "Nauja paraiška",
        newApplicationStep2: "Nauja paraiška",
        newApplicationStep3: "Nauja paraiška",
        newApplicationStep4: "Nauja paraiška",
        newApplicationStep5: "Nauja paraiška",
        editApplicationStep2: "Paraiška / Sutartis",
        editApplicationStep3: "Paraiška / Sutartis",
        editApplicationStep4: "Paraiška / Sutartis",
        editApplicationStep5: "Paraiška / Sutartis",
        searchApplications: "Paieška",
        accountSettings: "Paskyros valdymas",
        reports: "Ataskaitos",
        spreadsheet: "Skaičiuoklė",
        accounts: "Paskyros",
        accountEdit: "Keisti paskyrą",
        accountCreate: "Sukurti paskyrą",
        recognizer: "Dokumentų atpažinimas",
        debtReport: "Skolų ataskaita",
        passwordRemind: "Slaptažodžio priminimas",
      },
    },
    login: {
      userName: "vartotojo vardas",
      password: "slaptažodis",
      forgotPassword: "pamiršau slaptažodį",
      login: "prisijungti",
      loginIn: "prisijungimas",
      logOut: "atsijungti",
      remindPassword: "slaptažodžio priminimas",
      remind: "priminti",
      userEmail: "el. paštas",
      userPhone: "telefono numeris",
      forgotPasswordCallHelp: "Arba skambinkite tel.",
      checkEmail: "Ačiū. Pasitikrinkite savo el. paštą!",
      confirmSmsCode: "Įveskite SMS žinute gautą kodą",
      verifySmsCode: "Patvirtinti",
      smsCode: "kodas",
      sessionExpires: "{sessionTimeout} buvote neaktyvus, prašome prisijungti iš naujo.",
      changePassword: "slaptažodžio keitimas",
      updatePassword: "pakeisti slaptažodį",
      passwordConfirmation: "pakartokite slaptažodį",
      passwordChanged: "slaptažodis pakeistas!",
      verificationTokenMissing: "vartotojo patvirtinimo kodo klaida",
      verificationLoginMissing: "vartotojo vardo nustatyti nepavyko",
      attempts: " bandymai.",
    },
    soap: {
      undefined: "SOAP sistemos klaida",
      "-404": "Tinklo klaida. Pabandykite vėliau.",
      0: "Viskas gerai",
      1: "Sistemos klaida", // internal WS API error [not a response from PVS]
      5: "Negalima sudaryti sutarties",
      10: "Nulinių pajamų atveju",
      1000: "Neteisingas slaptažodis",
      1001: "Neteisingas prisijungimas",
      1002: "Neteisingas prisijungimas",
      // eslint-disable-next-line quotes
      1004: 'Jūsų paskyra užblokuota. Paskyrą atblokuoti galite naudodamiesi <a href="password-remind">slaptažodžio priminimo funkcija</a> arba atblokuoti Jus gali pagrindinis įmonės vartotojas',
      // eslint-disable-next-line quotes
      1005: 'Jūsų paskyra užblokuota. Paskyrą atblokuoti galite naudodamiesi <a href="password-remind">slaptažodžio priminimo funkcija</a> arba atblokuoti Jus gali pagrindinis įmonės vartotojas',
      1020: "Neteisinga sesija",
      1021: "Neleidžiamas veiksmas",
      1030: "Naujas klientas, nerastas DB, reikia įvesti vardą ir pavardę",
      1031: "Neteisingas asmens kodas",
      1032: "Šio kliento neaptarnauti",
      1050: "Vartotojas su tokiais duomenimis nerastas",
      1051: "Rastas daugiau negu vienas vartotojas. Prašome kreiptis el. paštu <a href='mailto:komercija@sbl.lt'>komercija@sbl.lt</a> arba tel. nr. <a href='tel:837409156'>8-37 40 91 56</a>",
      1060: "Blogas paraiškos ID",
      1063: "Paraiška nerasta",
      1064: "Negalima ištrinti paraiškos",
      1065: "Iš paraiškos jau sudaryta sutartis",
      1080: "Nerastas vadybininkas",
      1090: "Nėra raktą atitinkančių vartotojų",
      1100: "Neteisinga sutarties būsena",
      1200: "Sutarties atmesti nebegalima",
      1300: "Paraiškos veiksmų žurnalas tuščias",
      1400: "Nurodytomis sąlygomis negalima suskaičiuoti grafiko [tuo pačiu sudaryti sutarties]",
      1500: "Nežinoma ataskaita",
      1600: "Sutarties patvirtinti nepavyko",
      1800: "Nepavyko suskaičiuoti siūlomų variantų",
      1900: "Nerasta jokių įrašų",
      2000: "Nerasta SBL kortelės sutartis!",
      2001: "DB grąžino tuščią atsakymą",
      2002: "PDF generavimo klaida",
      2101: "Nepavyko pakeisti sutarties būsenos atmetant",
      2120: "Klaida išsaugant pardavėją",
      2140: "Pardavėjas neturi priskirtų galiojančių salygų variantų",
      2200: "Dokumentas yra archyve.",
      // custom LPS codes
      900: "Toks vartotojo vardas ar el.paštas jau egzistuoja",
      contractSaved: "Paraiška išsaugota",
      signedAgreementDocumentUploaded: "Pasirašytas sutikimo dokumentas įkeltas",
      signedContractDocumentUploaded: "Pasirašytas sutarties dokumentas įkeltas",
      left: "Liko ",
      emptyClientEmail: "Nenurodytas kliento el. pašto adresas."
    },
    ecr: {
      spouseSignRequestOnEcrFailed: "Nepavyko inicijuoti sutuoktinio pasirašymo",
    },
    applications: {
      title: "paraiškos ir sutartys",
      accountNumber: "sąskaitos nr.",
      additionalAccount: "papildoma sąskaita",
      buyer: "pirkėjas",
      buyerFullName: "pardavėjo vardas ir pavardė",
      buyerName: "pirkėjo vardas",
      buyerPersonalCode: "pirkėjo asmens kodas",
      buyerSurname: "pirkėjo pavardė",
      code: "kodas",
      contractConditionVariant: "sutarties sąlygų variantas",
      paymentDay: "kredito mokėjimo diena",
      contractDate: "sutarties data",
      contractDurationMonths: "sutarties trukmė mėnesiais",
      contractNumber: "sutarties nr.",
      draftNumber: "paraiškos nr.",
      contractType: "sutarties tipas",
      createDate: "sudarymo data",
      creditLimit: "kredito limitas",
      creditSum: "kredito suma",
      filter: "filtruoti",
      hasIncomeCertificate: "yra pajamų pažyma",
      incomeType: "pajamų tipas",
      lowerWinterFee: "mažesnės įmokos žiemą",
      number: "nr.",
      product: "prekė",
      products: "prekės",
      seller: "pardavėjas",
      shopName: "parduotuvės pavadinimas",
      signatureType: "pasirašymo būdas",
      statusAll: "visi",
      application: "paraiška",
      archived: "archyvas",
      unsignedContract: "nepasirašyta sutartis",
      signedContract: "pasirašyta sutartis",
      rejectedContract: "atmesta sutartis",
      contract: "sutartis",
      status: "statusas",
      total: "iš viso įrašų",
      changeVisibleFields: "Stulpelių rodymo keitimas",
      fieldSettingsInfo: "* nustatymai iškart išsaugomi jūsų naršyklės atmintyje",
      dateFrom: "data nuo",
      dateTo: "data iki",
      exportXlsx: " Eksportuoti į XLSX",
      useWideTable: "Plati lentelė",
      sureToRemove: "Ar tikrai norite ištrinti?",
      sellerFullName: "Pardavėjo vardas ir pavardė",
      comment: "komentaras",
    },
    newApplication: {
      title: "nauja paraiška",
      sellerInfo: "pardavėjo info",
      actionLog: "Veiksmų žurnalas",
      download: "Jei norite atsisiųsti sutartį, atšaukite elektroninį pasirašymą",
      cancellation: "Sutarties atmesti negalima, kol neatšauktas elektroninis pasirašymas",
      lastStep: "Paskutinis veiksmas",
      chooseSeller: "rinktis pardavėją",
      next: "toliau",
      back: "atgal",
      companyName: "įmonės pavadinimas",
      seller: "pardavėjas",
      choose: "pasirinkti",
      buyerInfo: "pirkėjo informacija",
      personalCode: "asmens kodas",
      spousePersonalCode: "sutuoktinio asmens kodas",
      creditType: "kredito tipas",
      creditTypeNoSpouse: "neturi sutuoktinio",
      creditTypePersonal: "turi sutuoktinį, bet kreditas asmeniniams poreikiams",
      creditTypeSpouse: "turi sutuoktinį ir kreditas šeimos poreikiams",
      change: "keisti",
      spouseInformation: "sutuoktinio informacija",
      contractNumber: "sutarties nr.",
      draftNumber: "paraiškos nr.",
      phone: "telefonas",
      phoneNumber: "telefono numeris",
      phoneNumberShort: "tel. nr. ",
      personalCodeShort: "a.k.",
      spousePhoneNumber: "sutuoktinio telefono numeris",
      company: "įmonė",
      update: "atnaujinti",
      applicationDetails: "paraiškos duomenys",
      search: "ieškoti",
      clientProvidedData: "kliento pateikti duomenys",
      canIncomeCollapse: "ar pajamos gali sumažėti?",
      yes: "taip",
      no: "ne",
      income: "pajamos",
      spouseIncome: "sutuoktinio pajamos",
      incomeType: "pajamų tipas",
      spouseIncomeType: "kliento sutuoktinio pajamų tipas",
      unsignedDocuments: "nepasirašyti dokumentai",
      printDocuments: "spausdinti",
      emailDocuments: "siųsti el. paštu",
      incomeDeclarationExists: "yra pažyma apie 4 mėn. pajamas",
      workplace: "darbovietė",
      consumerCreditsPerMonth: "vartojimo kreditai, Eur/men",
      mortgagesPerMont: "būsto paskolos, Eur/men",
      creditUserIsMe: "naudosiu kreditą pats",
      politicalActivity: "politinis aktyvumas",
      marketingAgreements: "rinkodaros sutikimai",
      marketingSBLizingas: "SB lizingas",
      marketingSiauliuBankas: "Šiaulių bankas",
      marketingBonumPublicum: "Bonum Publicum",
      application: "paraiška",
      spouseApplication: "sutuoktinio paraiška",
      print: "spausdinti",
      signOnTablet: "pasirašyti plašetėje",
      contactManager: "susisiekti su vadybininku",
      incomeTypeHiredWorker: "samdomas darbuotojas",
      incomeTypePensioner: "pensininkas",
      incomeTypeOther: "kita",
      incomeTypeOfficer: "pareigūnas",
      incomeTypeHiredFarmer: "ūkininkas",
      creditorAccountNumber: "kreditoriaus sąskaitos numeris",
      name: "vardas",
      surname: "pavardė",
      email: "el. paštas",
      spouseEmail: "sutuoktinio el. paštas",
      placeholderPersonalCode: "pvz.: 30102036666",
      agreementFileName: "sutikimas",
      signedAgreementFileName: "sutikimas-pasirasytas",
      agreementFileNameSpouse: "sutuoktinio-sutikimas",
      signedAgreementFileNameSpouse: "sutuoktinio-sutikimas-pasirasytas",
      incomePlanned: "planuojamos pajamos ateityje",
      spouseIncomePlanned: "planuojamos sutuoktinio pajamos ateityje",
      politicianNameSurname: "politiko vardas, pavardė",
      politicalInstitution: "politinė institucija",
      politicianConnection: "politinėje veikloje dalyvauja",
      politicianMyself: "pats",
      politicianFamilyMember: "šeima",
      politicianColleague: "kolega",
      documentsForPrinting: "dokumentai spausdinimui",
      creditData: "kredito duomenys",
      financialLiabilities: "finansiniai įsipareigojimai",
      personalData: "asmeniniai duomenys",
      goods: "prekės",
      addEditGoods: "Pridėti / Redaguoti prekę",
      additionalData: "papildomi duomenys",
      contractConfirmation: "sutarties patvirtinimas",
      uploadDocument: "įkelti dokumentą",
      contractProject: "sutarties projektas",
      sendClientDocuments: "siųsti klientui dok",
      showMaxPosibleCredit: "parodyti didžiausią galimą kreditą",
      saveData: "išsaugoti duomenis",
      saveApplication: "išsaugoti paraišką",
      commonInformation: "standartinė informacija",
      allowInitiateContract: "leisti sudaryti sutartį",
      initiateContract: "sudaryti sutartį",
      abortContract: "atmesti sutartį",
      documentPackage: "dokumentų paketas",
      agreement: "sutikimas",
      agreementSpouse: "sutuoktinio sutikimas",
      contract: "sutartis",
      contractSpouse: "sutuoktinio sutartis",
      evaluationReport: "vertinimo ataskaita",
      debtReport: "skolų ataskaita",
      debtReportSpouse: "sutuoktinio skolų ataskaita",
      sign: {
        UNKNOWN_SIGN: "-",
        PAPER_SIGN: "popierinė",
        CENT_SIGN: "pavedimu",
        E_SIGN: "el. parašu",
        TABLET_SIGN: "planšetėje",
        INI_SIGN: "inicijuotas el. pasirašymas",
        INI_TABLET_SIGN: "inicijuotas el. pasirašymas planšete",
      },
      actionLogFields: {
        dateEndTime: "Data ir Laikas",
        user: "Vartotojas",
        final: "Veiksmas",
      },
      attachFile: "dokumento prisegimas",
      chooseFile: "pasirinkite failą",
      fileInputPlaceholder: "pasirinkite failą arba nuleiskite čia ...",
      dropFilePlaceholder: "nuleiskite failą čia ...",
      useTabletCamera: "fotografuoti planšetėje",
      fileUploadDisclaimer: "įsitikinkite, jog įkelta nuotrauka nėra susiliejusi, o dokumento duomenys aiškiai įskaitomi",
      save: "išsaugoti",
      documentType: "tipas",
      sum: "suma",
      advancePayment: "avansas",
      transferToClient: "pervesti klientui",
      duration: "trukmė",
      paymentDay: "mokėjimo diena",
      smallWinterPayments: "mažesnės įmokos žiemą",
      maxCreditPossibilities: "didžiausias galimas kreditas",
      searchingForProposedOptions: "ieškome variantų",
      monthlyPayment: "mėnesinė įmoka",
      maxCreditSum: "maksimali kredito suma",
      paymentSchedule: "mokėjimų grafikas",
      monthShort: "mėn.",
      creditInterest: "palūkanos",
      contractFee: "sutarties mokestis",
      administrationFee: "administravimo mokestis",
      totalCreditAmountValue: "Įmokų suma",
      conditionsOption: "sąlygų variantas",
      accountNumber: "sąskaitos Nr.",
      moneyUsage: "kredito paskirtis",
      dropDownChoose: "pasirinkite",
      source: "šaltinis",
      typeOfContract: "kredito rūšis",
      dateOfContract: "sutarties data",
      totalOutstandingAmount: "likutis su palūk.",
      limitAmount: "kredito limitas",
      outstandingInstalmentCount: "liko įmokų",
      instalmentCount: "įmokų skaičius",
      installmentAmount: "įmoka",
      refinancePossible: "ref",
      installmentAmountCorrected: "patikslinta įmoka",
      overdueDays: "vėlav. d.",
      purpose: "tikslas",
      contractType: {
        mortgage: "būsto",
        creditCards: "kortelė",
        leasing: "lizingas",
        consumer: "vartojimo",
        other: "kita",
      },
      creditor: "kreditorius",
      refinanceAmount: "refin. suma",
      payerCode: "mokėtojo kodas",
      emptyFinancialLiabilities: "Nėra duomenų apie finansinius įsipareigojimus",
      total: "iš viso",
      totalInRegistries: "iš viso registruose",
      totalByClient: "iš viso pateikta kliento",
      totalEstimation: "vertinama įmoka",
      totalEstate: "iš viso būsto kredito įmokos",
      totalConsumer: "iš viso vartojimo kredito įmokos",
      goodTitle: "prekė",
      goodGroup: "grupė",
      goodSerialNo: "Prekės / Serijos Nr.",
      goodPrice: "kaina",
      goodQuantity: "kiekis",
      goodUnits: "mato vnt.",
      goodInsured: "prekės draudimas",
      goodAmount: "suma",
      insured: "taip",
      noInsurance: "ne",
      goodsTotalPrice: "prekių suma",
      goodsTotalInsurance: "draudimo suma",
      city: "miestas",
      street: "gatvė",
      house: "namas",
      flat: "butas",
      postalCode: "pašto indeksas",
      isAddressUsageDifferent: "Gyvenamosios vietos adresas skiriasi nuo korespondencijos",
      documentValidUntil: "galioja iki",
      documentValidUntilValidation: "Reikšmė turi būti tarp {from} ir {to}",
      documentNumber: "numeris",
      spousePersonalData: "sutuoktinio asmeniniai duomenys",
      personalAddressTitle: "korespondecijos adresas",
      personalDocumentTitle: "dokumentas",
      documentControlLine: "kontrolinė eilutė",
      citizenship: "pilietybė",
      documentValidationNumber: "kontrolinė eilutė",
      additionalAccount: "papildoma sąskaita",
      additionalCode: "papildomas kodas",
      contractSigning: "sutarties pasirašymas",
      contractComment: "komentaras",
      creditCardData: "kortelės duomenys",
      creditCardOwner: "kredito kortelės savininkas",
      cardHolder: "kortelės savininkas",
      deliveryAddress: "pristatymo adresas",
      reviewChatHistory: "peržiūrėti pokalbių istoriją",
      signOnTab: "pasirašyti plašetėje",
      signOnTabCancel: "atšaukti pasirašymą",
      contractSigned: "sutartis pasirašyta",
      electronicSigning: "el. pasirašymas",
      cancelElectronicSigning: "atšaukti el. pasirašymą",
      standardInformationFileName: "standartine-informacija",
      contractApprove: "patvirtinti sutartį",
      contractDiscard: "atmesti sutartį",
      confirmContractCancellation: "Sutarties atmetimas",
      sureToCancelContract: "Atmesti sutartį Nr {contract} ?",
      abortCancellation: "atšaukti",
      contractConditionsCompletion: "sutarties atitikimas kriterijams",
      errorAmountTooLarge: "Suma didesnė už prekių sumą",
      errorContactManager: "susisiekite su vadybininku",
      failedToLoad: "paraiška nerasta",
      controlLineNumbersInvalid: "įvestos neteisingos kontrolinės eilutės reikšmės",
      scanDocument: "skenuoti dokumentą",
      scanProductsQr: "skenuoti prekę",
      readDeviceCamera: "naudokite esamo įrenginio kamerą",
      readUploadedImage: "įkelkite dokumento nuotrauką",
      readViaQR: "Naudokite kito įrenginio kamerą",
      problemWithQRScan: "Nuskaityti dokumento nepavyko",
      readQRInstructions: "prašome atidaryti mainų nuorodą išmaniajame įrenginyje su QR skaitytuvu",
      incorrectFyleType: "Failo tipas neatpažįstamas, tinka tik JPEG/JPG, PNG arba PDF",
      combinedIncomeValidationError: "Šeimos pajamų suma turi būti didesnė už 0",
      incorrectAdvanceValue: "Netinkama avanso reikšmė",
      sbInsuranceValue: "SB Draudimo Įmoka",
      chooseValidCondition: "Pasirinkite galiojantį sąlygų variantą",
      pickAmountOrCondition: "Pasirinkite kitą sumą arba salygų variantą",
      contractMade: "Sutartis sudaryta!",
      contentNotFound: "Puslapis nerastas",
      permissionDenied: "Neturite teisių šio puslapio peržiūrai",
      fileUpload: {
        personalDocCopy: "Asmens dokumento kopija",
        incomeDeclaration: "Pažyma apie pajamas",
        idFrontSide: "ID kortelės priekis",
        idBackSide: "ID kortelės galas",
        passport: "Pasas",
        spouseIdFrontSide: "Sutuoktinio ID kortelės priekis",
        spouseIdBackSide: "Sutuoktinio ID kortelės galas",
        spousePassport: "Sutuoktinio pasas",
        driverLicenseFront: "Vairuotojo pažymėjimo priekis",
        driverLicenseBack: "Vairuotojo pažymėjimo galas",
        spouseDriverLicenseFront: "Sutuoktinio vairuotojo pažymėjimo priekis",
        spouseDriverLicenseBack: "Sutuoktinio vairuotojo pažymėjimo galas",
        sbInsuranceDoc: "SB draudimo dok.",
        refinanceDoc: "Refinansavimo dok.",
        otherDoc: "Kita",
        residencePermitFront: "Leidimo nuolat g. Lietuvoje priekis",
        residencePermitBack: "Leidimo nuolat g. Lietuvoje galas",
        spouseResidencePermitFront: "Sutuoktinio leidimo nuolat g. Lietuvoje priekis",
        spouseResidencePermitBack: "Sutuoktinio leidimo nuolat g. Lietuvoje galas",
        decisionApprovalDocument: "Sprendimo patvirtinimo dok.",
      },
      documentUploads: {
        needToUploadDocuments: "Įkelkite pasirinktų tipų dokumentus į sistemą ir tada pakartokite pasirašymo planšete veiksmą",
        uploadPersonalDocument: "Įkelkite asmens dokumento kopiją",
        uploadPersonalDocumentSpouse: "Įkelkite sutuoktinio asmens dokumento kopiją",
        uploadPassport: "Įkelkite paso dokumento kopiją",
        uploadPassportSpouse: "Įkelkite sutuoktinio paso dokumento kopiją",
        uploadResidencePermit: "Įkelkite gyvenamosios vietos dokumento kopiją",
        uploadResidencePermitSpouse: "Įkelkite sutuoktinio gyvenamosios vietos dokumento kopiją",
        documentUploadedSuccessfully: "Dokumentas įkeltas"
      }
    },
    news: {
      title: "Naujienos",
      hello: "Sveiki",
    },
    searchApplications: {
      title: "ieškoti",
      error: "įveskite paraiškos numerį",
    },
    accountSettings: {
      title: "nustatymai",
      accountDetails: "paskyros informacija",
      submitChanges: "keisti paskyros duomenis",
      name: "vardas",
      surname: "pavardė",
      email: "el. paštas",
      oldPassword: "senas slaptažodis",
      newPassword: "naujas slaptažodis",
      passwordConfirmation: "pakartoti slaptažodį",
      saved: "informacija atnaujinta",
      phone: "mobilus telefonas",
      authenticationMethod: "autentifikacija",
      authenticationByEmail: "el. paštu",
      authenticationBySMS: "SMS žinute",
      authenticationUndecided: "nepasirinkta",
      userName: "Vartotojo vardas",
      shop: "Parduotuvė",
    },
    reports: {
      title: "paraiškos",
      preview: "peržiūrėti ataskaitą",
      download: "atsisiųsti ataskaitą",
    },
    spreadsheet: {
      title: "skaičiuoklė",
      conditions: "sąlygos",
      extendedForm: "išplėstinė užklausa",
      shortForm: "trumpa užklausa",
      duration: "trukmė, mėn.",
      amount: "suma, Eur",
      calculate: "skaičiuoti",
      reset: "iš naujo",
      results: "skaičiavimo rezultatai",
      purpose: "paskolos tikslas",
      familyMembersCount: "šeimos narių skaičius",
      netIncome: "pajamos, atskaičius visus mokesčius, Eur/mėn",
      spouseNetIncome: "sutuoktinio pajamos, atskaičius mokesčius, Eur/mėn",
      monthlyCreditPayments: "vartojimo kreditų įmokos, Eur/mėn",
      mortgagePayments: "būsto paskolų įmokos, Eur/mėn",
      birthDate: "gimimo data",
      showMaxCredit: "rodyti didžiausią galimą kreditą",
      createApplication: "sukurti paraišką",
      monthlyPayment: "mėnesinė įmoka",
      sumOfPayments: "įmokų suma",
      interestRate: "palūkanos",
      bkkmn: "BKKMN",
      contractFee: "sutarties mokestis",
      administrationFee: "administravimo mokestis",
      totalCreditAmount: "kredito suma",
      term: "mokėjimo terminas",
      period: "periodas",
      unpaidCredit: "likusi negrąžinta suma",
      creditPayment: "grąžinama kredito suma",
      interestPayment: "palūkanos",
      totalContribution: "įmoka",
      secondCredit: "antras kreditas",
      firstCreditPayments: "pirmojo kredito įmokos",
      paymentScheduleFileName: "mokėjimo-grafikas",
    },
    footer: {
      // eslint-disable-next-line quotes
      rightsReserved: '© UAB "SB lizingas". Visos teisės saugomos.',
    },
    goodsModal: {
      heading: "pakeisti prekę",
      goods: "prekės",
      serialNo: "Prekės / Serijos nr.",
      group: "prekės grupė",
      price: "kaina",
      quantity: "kiekis",
      addProduct: "pridėti prekę",
      scanCode: "skenuoti",
      save: "išsaugoti",
      purchaseInsurance: "Pirkinių draudimas",
      warrantyDuration: "Gamintojo garantija",
    },
    serviceWorker: {
      updated: "Sistema atsinaujino",
      reloadMessage: "Sistemos atnaujinimai paruošti. Pakeitimai įsigalios atsidarius naują puslapį arba perkrovus esamą.",
      reload: "Perkrauti dabar",
    },
    accounts: {
      list: {
        title: "Paskyros",
        name: "Vardas, Pavardė",
        username: "Prisijungimo vardas",
        email: "El. pašto adresas",
        role: "Rolė",
        active: "Aktyvus",
        store: "Parduotuvė",
        lastLogin: "Paskutinis prisijungimas",
        yes: "Taip",
        no: "Ne",
        show: "Rodyti",
        records: "įrašus",
        search: "Ieškoti",
        edit: "Keisti",
        create: "Sukurti paskyrą",
        deactivate: "Sustabdyti",
        confirmDeactivate: "Ar tikrai?",
      },
      edit: {
        title: "Keisti paskyrą",
        loadFailed: "Vartotojas su tokiais duomenimis nerastas",
        passwordError:
          "Slaptažodyje būtinai turi būti naudojama: didžiosios raidės; mažosios raidės; specialūs simboliai (~!@#$%^&*()_+[]\\;’,./{}|:\"<>?);",
      },
      create: {
        title: "Sukurti paskyrą",
      },
      warrant: {
        warn: "Dėmesio! Patvirtinkite darbuotojų įgaliojimus ",
        warn2: "lange. Nepatvirtinus iki ",
        warn3: " vartototai taps neaktyvūs",
        confirmMessage1: "Ar patvirtinti įgaliotus asmenis esančius lentelėje sudarinėti vartojimo kreditus?",
        confirm: "Tvirtinti",
        // eslint-disable-next-line quotes
        confirmMessage2: 'Įgaliotus asmenis, kurie jau nebedirba įmonėje, galite pašalinti paspausdami prie darbuotojo "Sustabdyti"',
        accounts: "Paskyros",
      },
    },
    roles: {
      0: "Pardavėjas",
      1: "Manager",
      3: "Vadovas",
    },
    contractTypes: {
      goods: "Kredito prekių sutartis",
      3: "Kredito prekių sutartis",
      creditAccount: "Kredito sąskaitos sutartis",
      5: "Kredito sąskaitos sutartis",
      creditCard: "Kredito kortelės sutartis",
      6: "Kredito kortelės sutartis",
      credit: "Internetinė sutartis",
      7: "Internetinė sutartis",
    },
    mazosImokos: {
      0: "Ne",
      1: "Taip",
    },
    pajamuPazyma: {
      0: "Ne",
      1: "Taip",
    },
    tableInfo: {
      totalRecordsDisplayed: "Rodomi įrašai nuo {startRecord} iki {endRecord} iš {totalRecords} įrašų",
      noPostsFound: "Įrašų nerasta",
      totalResults: " (atrinkta iš {totalResults} įrašų)",
    },
    microblink: {
      pointDocument: "Nukreipkite kamerą į priekinę dokumento pusę.",
      documentDetected: "Dokumentas aptiktas",
      documentNotDetected: "Aptikti nepavyko",
      recognitionFailed: "Dokumento atpažinti nepavyko, bandykite dar kartą",
      scanning: "Skenuojama...",
      rotateDocument: "Pakreipkite dokumentą",
      flipDocument: "Apverskite dokumentą",
      zoomIn: "Priartinkite dokumentą",
      zoomOut: "Atitraukite dokumentą",
      unhandledStatus: "Nenustatyta nuskaitymo būsena!",
      provideSpouseDocument: "Skenuokite sutuoktinio dokumentą arba įveskite asmens kodą",
      documentRecognized: "Dokumentas nuskaitytas",
      licenceError: "Skenerio licencija negalioja, nuskaitymas neįmanomas",
      qrCodeScanned: "QR kodas nuskaitytas, laukiame duomenų...",
    },
    websiteDisplaysBetterInOtherLanguages: {
      messageRow1: "Gerb. partneri,",
      messageRow2:
        "pastebėjome, kad naudojate Internet Explorer naršyklę. Ši naršyklė yra pasenusi ir daugelio LPS funkcionalumų nebepalaiko, todėl prašome jungiantis naudoti kitas naršykles (Microsoft Edge, Chrome, Firefox ir pan.).",
    },
    errors: {
      lengthRule: "Slaptažodį turi sudaryti ne mažiau kaip",
      caseRule: "Slaptažodyje turi būti naudojama bent viena",
      specialCharRule: "Slaptažodyje turi būti naudojamas bent vienas",
      lengthRuleValue: "8 simboliai",
      lowerCaseRuleValue: "mažoji raidė",
      capitalCaseRuleValue: "didžioji raidė",
      numberRuleValue: "skaičius",
      specialCaseRuleValue: "specialus simbolis",
    },
  },
  en: {
    meta: {
      titles: {
        login: "Joining",
        applications: "Applications and Contracts",
        news: "News",
        newApplication: "New application",
        newApplicationStep2: "New Application",
        newApplicationStep3: "New Application",
        newApplicationStep4: "New Application",
        newApplicationStep5: "New application",
        editApplicationStep2: "Application / Contract",
        editApplicationStep3: "Application / Contract",
        editApplicationStep4: "Application / Contract",
        editApplicationStep5: "Application / Contract",
        searchApplications: "Search",
        accountSettings: "Account management",
        reports: "Reports",
        spreadsheet: "Spreadsheet",
        accounts: "Accounts",
        accountEdit: "Edit account",
        accountCreate: "Create account",
        recognizer: "Document recognizer",
        debtReport: "Debt statement",
        passwordRemind: "Password reminder",
      },
    },
    login: {
      userName: "user name",
      password: "password",
      forgotPassword: "forgot password",
      login: "login",
      loginIn: "login",
      logOut: "sign out",
      remindPassword: "remind password",
      remind: "remind",
      userEmail: "e-mail",
      userPhone: "phone number",
      forgotPasswordCallHelp: "Or call",
      checkEmail: "Thank you. Check your email for instructions!",
      confirmSmsCode: "Enter the code received by the SMS message",
      verifySmsCode: "Confirm",
      smsCode: "code",
      sessionExpires: "You have been inactive for {sessionTimeout}, please log in again.",
      changePassword: "Change password",
      updatePassword: "update the password",
      passwordConfirmation: "Repeat password",
      passwordChanged: "The password has been changed!",
      verificationTokenMissing: "user confirmation code error",
      verificationLoginMissing: "user name validation failed",
      attempts: " attempts",
    },
    soap: {
      undefined: "SOAP system error",
      "-404": "Network error. Please try again later.",
      0: "Everything is fine",
      1: "sistemosKlaida", //InternalWsApiError [notAResponseFromPvs]
      5: "Cannot enter into a contract",
      10: "In the case of zero income",
      1000: "Invalid Password",
      1001: "Invalid Login",
      1002: "Invalid Login",
      1004: "Your account is disabled. To unlock the account, please use the password remind form, or ask the master account holder",
      1005: "Your account is disabled. To unlock the account, please use the password remind form, or ask the master account holder",
      1020: "Invalid session",
      1021: "Action denied",
      1030: "New client not found in DB, name required",
      1031: "Invalid personal code",
      1032: "Do not serve this customer",
      1050: "A user with such information not found",
      1051: "More than one user found. Please contact us by e-mail <a href='mailto:kommercija@sbl.lt'>kommercija@sbl.lt</a> or phone no. <a href='tel:837409156'> 8-37 40 91 56",
      1060: "Bad Application Id",
      1063: "Application not found",
      1064: "Cant delete Application",
      1065: "The application number is already assigned to contract",
      1080: "Manager Not Found",
      1090: "No users matching the key",
      1100: "Incorrect status of the contract",
      1200: "The contract can no longer be rejected",
      1300: "Application action log is empty",
      1400: "Under the conditions set out, it is not possible to calculate the schedule [at the same time to conclude the contract].",
      1500: "Unknown Report",
      1600: "Failed to approve contract",
      1800: "Failed to count suggestions",
      1900: "No values found",
      2000: "No SBL card agreement found!",
      2001: "DB returned an empty response",
      2002: "PDF Generation Error",
      2101: "Failed to change contract status by rejecting",
      2120: "Error saving seller",
      2140: "Seller has no custom terms in effect.",
      2200: "Document is in archive.",
      // custom LPS codes
      900: "Such username or email already exists",
      contractSaved: "Paraiška išsaugota",
      signedAgreementDocumentUploaded: "Signed agreement document uploaded",
      signedContractDocumentUploaded: "Signed contract document uploaded",
      left: "Left ",
      emptyClientEmail: "The customer's e-mail address is not specified.",
    },
    ecr: {
      spouseSignRequestOnEcrFailed: "Failed to initiate spouse signing",
    },
    applications: {
      title: "Applications and contracts",
      accountNumber: "account no.",
      additionalAccount: "additional account",
      buyer: "buyer",
      buyerFullName: "the name of the seller",
      buyerName: "buyer's name",
      buyerPersonalCode: "buyer's personal code",
      buyerSurname: "the name of the buyer",
      code: "code",
      contractConditionVariant: "a variant of the terms of the contract",
      paymentDay: "payment day",
      contractDate: "date of contract",
      contractDurationMonths: "contract duration in months",
      contractNumber: "contract no.",
      draftNumber: "draft agreement ID",
      contractType: "type of contract",
      createDate: "date of conclusion",
      creditLimit: "credit limit",
      creditSum: "credit amount",
      filter: "filter",
      hasIncomeCertificate: "is a certificate of income",
      incomeType: "income type",
      lowerWinterFee: "lower premiums in winter",
      number: "no.",
      product: "product",
      products: "goods",
      seller: "seller",
      shopName: "store name",
      signatureType: "method of signing",
      statusAll: "all",
      application: "application",
      archived: "archive",
      unsignedContract: "unsigned contract",
      signedContract: "signed contract",
      rejectedContract: "contract rejected",
      contract: "contract",
      status: "status",
      total: "total records",
      changeVisibleFields: "Change the display of columns",
      fieldSettingsInfo: "* settings are instantly saved in Your browser's memory",
      dateFrom: "date from",
      dateTo: "date to",
      exportXlsx: " Export to XLSX",
      useWideTable: "Wide table",
      sureToRemove: "Are you sure you want to delete?",
    },
    newApplication: {
      title: "new application",
      sellerInfo: "seller info",
      actionLog: "Action log",
      download: "To download the contract, cancel the electronic signature",
      cancellation: "The contract may not be rejected until the electronic signature has been revoked",
      lastStep: "The last step",
      chooseSeller: "choose seller",
      next: "next",
      back: "back",
      companyName: "company name",
      seller: "seller",
      choose: "Choose",
      buyerInfo: "buyer information",
      personalCode: "personal code",
      spousePersonalCode: "spouse personal code",
      creditType: "credit type",
      creditTypeNoSpouse: "has no spouse",
      creditTypePersonal: "have a spouse but credit for personal use",
      creditTypeSpouse: "have a spouse and credit will be used for family needs",
      change: "change",
      spouseInformation: "spouse information",
      contractNumber: "contract number",
      phone: "phone",
      draftNumber: "draft agreement ID",
      phoneNumber: "phone number",
      phoneNumberShort: "phone",
      personalCodeShort: "p.c.",
      spousePhoneNumber: "spouse phone number",
      company: "companies",
      update: "update",
      applicationDetails: "application details",
      search: "search",
      clientProvidedData: "data provided by the customer",
      canIncomeCollapse: "can revenue fall?",
      yes: "yes",
      no: "no",
      income: "income",
      spouseIncome: "spouse income",
      incomeType: "income type",
      spouseIncomeType: "type of customer spouse's income",
      unsignedDocuments: "unsigned documents",
      printDocuments: "print",
      emailDocuments: "send emailby mail",
      incomeDeclarationExists: "there is a certificate for about 4 months income",
      workplace: "workplace",
      consumerCreditsPerMonth: "consumer credits, Eur / month",
      mortgagesPerMont: "housing loans, Eur / month",
      creditUserIsMe: "i will use the credit myself",
      politicalActivity: "political activism",
      marketingAgreements: "marketing consents",
      marketingSBLizingas: "SB lizingas",
      marketingSiauliuBankas: "Šiaulių bankas",
      marketingBonumPublicum: "Bonum Publicum",
      application: "application",
      spouseApplication: "spouse application",
      print: "print",
      signOnTablet: "sign on tablet",
      signOnTabCancel: "cancel signing",
      contactManager: "contact manager",
      incomeTypeHiredWorker: "hired worker",
      incomeTypePensioner: "pensioner",
      incomeTypeOther: "other",
      incomeTypeOfficer: "officer",
      incomeTypeHiredFarmer: "farmer",
      name: "name",
      surname: "surname",
      email: "e-mail",
      spouseEmail: "spouse e-mail",
      placeholderPersonalCode: "e.g.: 30102036666",
      agreementFileName: "agreement",
      signedAgreementFileName: "agreement-signed",
      agreementFileNameSpouse: "spouse-agreement",
      signedAgreementFileNameSpouse: "spouse-agreement-signed",
      incomePlanned: "projected future income",
      spouseIncomePlanned: "projected future income of the spouse",
      politicianNameSurname: "the name of the politician",
      politicalInstitution: "political institution",
      politicianConnection: "politician connection",
      politicianMyself: "the customer himself",
      politicianFamilyMember: "family",
      politicianColleague: "colleague",
      documentsForPrinting: "documents for printing",
      creditData: "credit data",
      financialLiabilities: "financial liabilities",
      personalData: "personal data",
      goods: "goods",
      addEditGoods: "Add / Edit good",
      additionalData: "additional data",
      contractConfirmation: "confirmation of the contract",
      uploadDocument: "upload the document",
      contractProject: "the draft agreement",
      sendClientDocuments: "send to customer doc",
      showMaxPosibleCredit: "show the greatest possible credit",
      saveData: "save data",
      saveApplication: "save the application",
      commonInformation: "common information",
      allowInitiateContract: "allow the conclusion of the contract",
      initiateContract: "initiate contract",
      abortContract: "reject contract",
      documentPackage: "document package",
      agreement: "agreement",
      agreementSpouse: "spouse agreement",
      contract: "contract",
      contractSpouse: "spouse contract",
      evaluationReport: "assessment report",
      debtReport: "debt report",
      debtReportSpouse: "spouse's debt report",
      sign: {
        UNKNOWN_SIGN: "-",
        PAPER_SIGN: "on paper",
        CENT_SIGN: "pavedimu",
        E_SIGN: "e.Sign",
        TABLET_SIGN: "on tablet",
        INI_SIGN: "initiated e signing",
      },
      actionLogFields: {
        dateEndTime: "Date and time",
        user: "User",
        final: "Good luck",
      },
      attachFile: "attach file",
      chooseFile: "select the file",
      fileInputPlaceholder: "choose a file or drop it here...",
      dropFilePlaceholder: "drop file here...",
      useTabletCamera: "use tablet camera",
      fileUploadDisclaimer: "make sure the uploaded picture is not fused and the document data is clearly readable",
      save: "save",
      documentType: "type",
      sum: "sum",
      advancePayment: "advance payment",
      transferToClient: "transfer to client",
      duration: "duration",
      paymentDay: "payment day",
      smallWinterPayments: "lower payments in winter",
      maxCreditPossibilities: "max credit possibilities",
      searchingForProposedOptions: "we are looking for credit options",
      monthlyPayment: "monthly installment",
      maxCreditSum: "Maximum credit amount",
      paymentSchedule: "Payment schedule",
      monthShort: "mon.",
      creditInterest: "interest",
      contractFee: "contract fee",
      administrationFee: "administration fee",
      totalCreditAmountValue: "the total amount of credit",
      conditionsOption: "conditions option",
      accountNumber: "account No.",
      creditorAccountNumber: "creditor account No",
      moneyUsage: "credit purpose",
      dropDownChoose: "choose",
      source: "source",
      typeOfContract: "type of credit",
      dateOfContract: "date of the contract",
      totalOutstandingAmount: "balance with interest.",
      limitAmount: "credit limit",
      outstandingInstalmentCount: "remained contribution",
      instalmentCount: "number of contributions",
      installmentAmount: "down payment",
      refinancePossible: "ref",
      installmentAmountCorrected: "the adjusted down payment",
      overdueDays: "overdue days",
      purpose: "purpose",
      contractType: {
        mortgage: "mortgage",
        creditCards: "card",
        leasing: "leasing",
        consumer: "consumer",
        other: "other",
      },
      creditor: "creditor",
      refinanceAmount: "refin. sum",
      payerCode: "buyer code",
      emptyFinancialLiabilities: "there is no data on financial liabilities",
      total: "total",
      totalInRegistries: "total in registries",
      totalByClient: "total customer",
      totalEstimation: "assessed down payment",
      totalEstate: "total mortgage credit down payment",
      totalConsumer: "total consumer credit down payment",
      goodTitle: "good",
      goodGroup: "group",
      goodSerialNo: "Products / Serial No.",
      goodPrice: "price",
      goodQuantity: "amount",
      goodUnits: "units",
      goodInsured: "product insurance",
      goodAmount: "amount",
      insured: "yes",
      noInsurance: "no",
      goodsTotalPrice: "total goods amount",
      goodsTotalInsurance: "total insurance amount",
      city: "city",
      street: "street",
      house: "house",
      flat: "apartment",
      postalCode: "post code",
      isAddressUsageDifferent: "The address of the place of residence is different from correspondence",
      documentValidUntil: "valid until",
      documentValidUntilValidation: "Value should be between {from} and {to}",
      documentNumber: "number",
      spousePersonalData: "spouse's personal data",
      personalAddressTitle: "correspondition address",
      personalDocumentTitle: "document",
      documentControlLine: "control line",
      citizenship: "citizenship",
      documentValidationNumber: "control line",
      additionalAccount: "additional account",
      additionalCode: "additional code",
      contractSigning: "signing the contract",
      contractComment: "comment",
      creditCardData: "credit card data",
      creditCardOwner: "credit card owner",
      cardHolder: "card holder",
      deliveryAddress: "delivery address",
      reviewChatHistory: "view chat history",
      contractSigned: "contract signed",
      electronicSigning: "el. signing",
      cancelElectronicSigning: "cancel el. signing",
      standardInformationFileName: "standard-information",
      contractApprove: "confirm the contract",
      contractDiscard: "reject the contract",
      confirmContractCancellation: "Reject the contract",
      sureToCancelContract: "Reject contract number {contract} ?",
      abortCancellation: "cancel",
      contractConditionsCompletion: "contract condition completion",
      errorContactManager: "contact your manager",
      errorAmountTooLarge: "the amount is greater than the amount of goods",
      failedToLoad: "application not found",
      controlLineNumbersInvalid: "control numbers for the document are incorrect",
      scanDocument: "scan document",
      scanProductsQr: "scan product",
      readDeviceCamera: "use the camera of this device",
      readUploadedImage: "upload a photo of the document",
      readViaQR: "use the other device camera",
      problemWithQRScan: "Scanning the document failed",
      readQRInstructions: "please open the exchange link on your smartphone with QR reader",
      incorrectFyleType: "File type is not recognized, supported formats are JPEG/JPG, PNG or PDF",
      combinedIncomeValidationError: "The amount of family income must be greater than 0",
      incorrectAdvanceValue: "The advance value must be correct",
      sbInsuranceValue: "SB Insurance Value",
      chooseValidCondition: "Choose valid credit condition",
      pickAmountOrCondition: "Choose a different amount or conditions",
      contractMade: "Contract made!",
      contentNotFound: "Content not found",
      permissionDenied: "You do not have permission to view this page",
      fileUpload: {
        personalDocCopy: "Personal document copy",
        incomeDeclaration: "Reference of wage",
        idFrontSide: "ID card front",
        idBackSide: "ID card back",
        passport: "Passport",
        spouseIdFrontSide: "Spouse ID card front",
        spouseIdBackSide: "Spouse ID card back",
        spousePassport: "Spouse's passport",
        driverLicenseFront: "Driver license front",
        driverLicenseBack: "Driver license back",
        spouseDriverLicenseFront: "Spouse driver license front",
        spouseDriverLicenseBack: "Spouse driver license back",
        sbInsuranceDoc: "Sat insurance doc.",
        refinanceDoc: "Refinancing doc.",
        otherDoc: "Other",
        residencePermitFront: "Residence permit document front",
        residencePermitBack: "residence permit document back",
        spouseResidencePermitFront: "Spouse's residence permit document front",
        spouseResidencePermitBack: "Spouse's residence permit document back",
        decisionApprovalDocument: "Decision approval doc.",
      },
      documentUploads: {
        needToUploadDocuments: "Upload the selected types of documents into the system and try to sign again",
        uploadPersonalDocument: "Upload a copy of your identity document",
        uploadPersonalDocumentSpouse: "Upload a copy of your spouse's identity document",
        uploadPassport: "Upload a copy of your passport",
        uploadPassportSpouse: "Upload a copy of your spouse's passport",
        uploadResidencePermit: "Upload a copy of your residence permit",
        uploadResidencePermitSpouse: "Upload a copy of your spouse's residence permit",
        documentUploadedSuccessfully: "Document was uploaded"
      }
    },
    news: {
      title: "News",
      hello: "Hello",
    },
    searchApplications: {
      title: "Search",
      error: "enter application number",
    },
    accountSettings: {
      title: "settings",
      accountDetails: "account details",
      submitChanges: "change account details",
      name: "name",
      surname: "surname",
      email: "e-mail",
      oldPassword: "old password",
      newPassword: "new password",
      passwordConfirmation: "repeat the password",
      saved: "information updated",
      phone: "phone number",
      authenticationMethod: "authentication",
      authenticationByEmail: "by email",
      authenticationBySMS: "by SMS",
      authenticationUndecided: "not selected",
      userName: "Username",
      shop: "Shop",
    },
    reports: {
      title: "applications",
      preview: "preview report",
      download: "download report",
    },
    spreadsheet: {
      title: "calculator",
      conditions: "conditions",
      extendedForm: "extended form",
      shortForm: "short form",
      duration: "duration, month.",
      amount: "amount, Eur",
      calculate: "calculate",
      reset: "reset",
      results: "calculation results",
      purpose: "purpose",
      familyMembersCount: "family members count",
      netIncome: "income after taxes, Eur/month",
      spouseNetIncome: "spouse income after taxes, Eur/month",
      monthlyCreditPayments: "monthly credit payments",
      mortgagePayments: "monthly morgage payments",
      birthDate: "birthday",
      showMaxCredit: "show max possible credit",
      createApplication: "create application",
      monthlyPayment: "monthly payment",
      sumOfPayments: "sum of payments",
      interestRate: "interest rate",
      bkkmn: "BKKMN",
      contractFee: "contract fee",
      administrationFee: "administration fee",
      totalCreditAmount: "credit amount",
      term: "term",
      period: "period",
      unpaidCredit: "unpaid credit",
      creditPayment: "credit payment",
      interestPayment: "interest payment",
      totalContribution: "total contribution",
      secondCredit: "second credit",
      firstCreditPayments: "first credit payments",
      paymentScheduleFileName: "payment-cshedule",
    },
    footer: {
      // eslint-disable-next-line quotes
      rightsReserved: '© UAB "SB lizingas". All rights reserved',
    },
    goodsModal: {
      heading: "Change goods",
      goods: "goods",
      serialNo: "Goods / Serial no.",
      group: "Products group",
      price: "price",
      quantity: "amount",
      addProduct: "Add good",
      scanCode: "scan",
      save: "Save",
      purchaseInsurance: "Purchase insurance",
      warrantyDuration: "Manufacturer's warranty",
    },
    serviceWorker: {
      updated: "The system has been renewed",
      reloadMessage: "System updates are ready. The changes will activated with the vizit of a new page or on reloading the existing one.",
      reload: "Reload now",
    },
    accounts: {
      list: {
        title: "Accounts",
        name: "Name",
        username: "Username",
        email: "Email",
        role: "Role",
        active: "Active",
        store: "Store",
        lastLogin: "Last login",
        yes: "Yes",
        no: "No",
        show: "Show",
        records: "records",
        search: "Search",
        edit: "Edit",
        create: "Create account",
        deactivate: "Deactivate",
        confirmDeactivate: "Are you sure?",
      },
      edit: {
        title: "Edit account",
        loadFailed: "A user with such information not found",
        passwordError:
          "The password must use: capital letters; lowercase letters; special characters (~! @ # $% ^ & * () _ + [] \\; ',. / {} |: \"<>?);",
      },
      create: {
        title: "Create account",
      },
      warrant: {
        warn: "Attention! Please confirm warrant employees in ",
        warn2: "page, to prevent them to be disabled after ",
        warn3: " .",
        confirmMessage1: "Confirm below employess to allow them to administer the loan contracts?",
        confirm: "Confirm",
        confirmMessage2: "Please deactivate those employees, who are not working at the company anymore.",
        accounts: "Accounts",
      },
    },
    roles: {
      0: "Seller",
      1: "Manager",
      2: "Supervisor",
    },
    contractTypes: {
      goods: "Credit goods contract",
      3: "Credit goods contract",
      creditAccount: "Credit account contract",
      5: "Credit account contract",
      creditCard: "Credit card contract",
      6: "Credit card contract",
      credit: "Online contract",
      7: "Online contract",
    },
    mazosImokos: {
      0: "No",
      1: "Yes",
    },
    pajamuPazyma: {
      0: "No",
      1: "Yes",
    },
    tableInfo: {
      totalRecordsDisplayed: "Displayed records from {startRecord} to {endRecord} of {totalRecords}",
      totalResults: " (selected from {totalResults} records)",
    },
    microblink: {
      pointDocument: "Point the camera to the front side of the document.",
      documentDetected: "The document was detected",
      documentNotDetected: "Detection failed",
      recognitionFailed: "Document recognition failed, try again",
      scanning: "Scaninng...",
      rotateDocument: "Tilt the document",
      flipDocument: "Turn the document",
      zoomIn: "Zoom in the document",
      zoomOut: "Zoom out the document",
      unhandledStatus: "Unidentified scan status!",
      provideSpouseDocument: "Scan the spouse's document or enter personal code",
      documentRecognized: "Document recognized",
      licenceError: "Scanner license is invalid, scanning impossible",
      qrCodeScanned: "QR code used, waiting for the data...",
    },
    websiteDisplaysBetterInOtherLanguages: {
      messageRow1: "Website displays better on modern browsers",
    },
    errors: {
      lengthRule: "Password must contain at least one",
      caseRule: "Password must contain at least one",
      specialCharRule: "Password must contain at least one",
      lengthRuleValue: "8 characters long",
      lowerCaseRuleValue: "lowercase letter",
      capitalCaseRuleValue: "capital letter",
      numberRuleValue: "number",
      specialCaseRuleValue: "special character",
    },
  },
};

export default translations;
