
import mixins from "vue-typed-mixins";
import { mapActions, mapGetters } from "vuex";
import { BIconEnvelope, BIconTelephone, BIconPower } from "bootstrap-vue";
import NotificationsDisplay from "@/components/notificationsDisplay.vue";
import { Validator } from "vee-validate";
import { combineExpiredSessionRoutes, routerPaths } from "@/router/routerPaths";
import swUpdate from "./mixins/swUpdate";

import router from "./router";
import TimeRequests from "./mixins/timeRequests";
import { GlobalStorageKeys } from "./interfaces/applicationSettings";
import ChatContainer from "./components/chat/chatContainer.vue";

// export default Vue.extend({
export default mixins(swUpdate, TimeRequests).extend({
  name: "App",
  components: {
    BIconEnvelope,
    BIconTelephone,
    BIconPower,
    NotificationsDisplay,
    ChatContainer,
  },
  created() {
    // this will allow to monitor session related changes on another tab
    window.addEventListener("storage", this.onStorageUpdate);
    const unwatch = this.$watch(
      () => this.$route,
      (route) => {
        if (route.name == "DebtReport") {
          this.isDebtPage = route.name;
        }
        unwatch();
      }
    );
  },
  mounted() {
    this.selectedLocale = this.locale;
    this.helpPhone = process.env.VUE_APP_HELP_INFO_PHONE || "";
    this.managerEmail = process.env.VUE_APP_MANAGER_EMAIL_ADDRESS || "";
    this.version = process.env.VUE_APP_PACKAGE_VERSION || "";
    this.workingHoursWeekends = process.env.VUE_APP_WORKING_HOURS_WEEKENDS || "";
    this.workingHoursWorkdays = process.env.VUE_APP_WORKING_HOURS_WORKDAYS || "";
    this.mounted = true;
    this.monitorSessionTimeout();
  },
  data() {
    return {
      isDebtPage: "",
      routerPaths,
      helpPhone: "",
      managerEmail: "",
      version: "",
      workingHoursWeekends: "",
      workingHoursWorkdays: "",
      selectedLocale: "lt",
      languageOptions: [
        { id: 1, text: "LT", locale: "lt" },
        { id: 2, text: "EN", locale: "en" },
      ],
      sessionExtendTimeout: undefined as number | any,
      sessionExpirationInterval: undefined as number | any,
      mounted: false,
    };
  },
  methods: {
    ...mapActions({
      logOut: "auth/setUserLoggedOut",
      logIn: "auth/setUserLoggedIn",
      storeLocale: "auth/storeLocale",
      extendSession: "auth/extendSession",
      storeApplicationPendingState: "newApplication/storePendingState",
      showSessionExpiredError: "notifications/showSessionExpiredError",
      setSessionTimeout: "auth/setSessionTimeout",
      setSessionExpired: "auth/setSessionExpired",
    }),
    logOutAndRedirect() {
      clearInterval(this.sessionExpirationInterval);
      clearTimeout(this.sessionExtendTimeout);
      this.logOut();
    },
    changeLocale(event: string) {
      this.storeLocale(event);
      this.$root.$i18n.locale = event;
      this.$nextTick(() => Validator.localize(`${event}`, [event]));

      // translate current document title
      document.title = this.$t(this.$route.meta ? this.$route.meta.title : "").toString();
    },
    handleUserActivity() {
      if (!this.isLoggedIn || this.isManager || !this.mounted) {
        return;
      }

      clearTimeout(this.sessionExtendTimeout);

      if (this.isSessionExpired) {
        this.showSessionExpiredError(this.sessionTimeout);
        return;
      }

      this.sessionExtendTimeout = setTimeout(() => {
        this.checkSessionExpired(true).then((result) => {
          if (result) {
            this.showSessionExpiredError(this.sessionTimeout);
            this.redirectOnSessionTimeout();
          }

          // this will restart session check interval
          this.monitorSessionTimeout();
        });
      }, 1000);
    },
    redirectOnSessionTimeout() {
      if (
        !combineExpiredSessionRoutes(this.applicationsInSession || 0).includes(this.$route.path as routerPaths) &&
        ![routerPaths.NEWS, routerPaths.ROOT].includes(router.currentRoute.path as routerPaths)
      ) {
        router.push({ path: routerPaths.NEWS });
      }
    },
    monitorSessionTimeout() {
      if (!this.isLoggedIn || this.isManager || !this.mounted) {
        return;
      }

      clearInterval(this.sessionExpirationInterval);

      this.sessionExpirationInterval = setInterval(() => {
        if (this.isSessionExpired) {
          this.showSessionExpiredError(this.sessionTimeout);
          this.redirectOnSessionTimeout();

          clearInterval(this.sessionExpirationInterval);

          return;
        }

        this.checkSessionExpired().then((result) => {
          if (result) {
            this.showSessionExpiredError(this.sessionTimeout);
            this.redirectOnSessionTimeout();

            clearInterval(this.sessionExpirationInterval);
          }
        });
      }, (this.sessionTimeout + 10) / 2); // <- hit interval right after sessionTimeout happens
    },
    onStorageUpdate(storageEvent: StorageEvent) {
      switch (storageEvent.key) {
        case "sessionExpires":
          if (storageEvent.newValue) {
            this.timeNow()
              .then((res) => {
                if (storageEvent.newValue && +storageEvent.newValue <= +res) {
                  this.setSessionExpired(true);
                  this.showSessionExpiredError(this.sessionTimeout);
                  this.redirectOnSessionTimeout();
                  return;
                }
              })
              .catch((error) => {
                console.log("time sync error", error);
              });
          }

          this.monitorSessionTimeout();
          this.extendSession(storageEvent.newValue);
          break;
        case "user":
          if (!storageEvent.newValue) {
            this.logOutAndRedirect();
          }
          break;
      }
    },
    checkSessionExpired(extend = false) {
      return new Promise((resolve, reject) => {
        if (this.isLoggedIn && !this.isManager && !this.isSessionExpired) {
          this.timeNow()
            .then((res) => {
              if (this.sessionExpires <= +res) {
                this.setSessionExpired(true);
                resolve(true);
              }

              if (extend) {
                this.extendSession(+res + this.sessionTimeout);
              }

              resolve(false);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(false);
        }
      });
    },
    resetApplicationsFilters() {
      sessionStorage.removeItem(GlobalStorageKeys.APPLICATION_FILTER_PARAMS);
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      user: "auth/user",
      locale: "auth/locale",
      isSupervisor: "auth/isSupervisor",
      isManager: "auth/isManager",
      sessionExpires: "auth/sessionExpires",
      isSessionExpired: "auth/isSessionExpired",
      sessionTimeout: "auth/sessionTimeout",
      applicationsInSession: "auth/applicationsInSession",
    }),
  },
  watch: {
    $route: function (val) {
      // if app was updated
      // this will initiate app reload after navigating to another route
      this.refreshApp();
      // grab meta title from route config, translate it and put on document title
      document.title = this.$t(val.meta.title)?.toString() || "SB Lizingas LPS";
    },
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
});
