/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB");
    },
    registered(registration) {
      console.log("Service worker has been registered.");
      setInterval(() => {
        // this code is used once per app lunch and never hit again until next open
        // this will help long sitting users to check for update at least once and hour without reloading
        registration.update();
      }, 1000 * 60 * 60); // every 60 min
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      document.dispatchEvent(new CustomEvent("swUpdated", { detail: registration }));
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
