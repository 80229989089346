export const validationMessagesLt: any = {
  _default: (field: string) => `${field} reikšmė netinkama`,
  after: (field: string, [target]: string) => `Laukelyje ${field} turi būti po ${target}`,
  alpha: (field: string) => `Laukelyje ${field} leidžiamos tik raidės`,
  alpha_dash: (field: string) => `Laukelyje ${field} leidžiamos tik raidės, skaičiai bei brūkšneliai`,
  alpha_num: (field: string) => `Laukelyje ${field} leidžiamos tik raidės ir skaičiai`,
  alpha_spaces: (field: string) => `Laukelyje ${field} leidžiamos tik raidės ir tarpai`,
  before: (field: string, [target]: string) => `${field} turi būti prieš ${target}`,
  between: (field: string, [min, max]: any) => `Laukelio ${field} reikšmė turi būti tarp ${min} ir ${max}`,
  confirmed: (field: string) => `Laukelio ${field} patvirtinimas nesutampa`,
  credit_card: (field: string) => `Laukelis ${field} neteisingas`,
  date_between: (field: string, [min, max]: any) => `Laukelio ${field} reikšmė turi būti tarp ${min} ir ${max}`,
  date_format: (field: string, [format]: string) => `Laukelio ${field} formatas privalo būti toks - ${format}`,
  decimal: (field: string, [decimals = "*"] = []) =>
    `Laukelis ${field} turi būti skaitmuo su${!decimals || decimals === "*" ? "" : " " + decimals} skaičium(-ias) po kablelio`,
  digits: (field: string, [length]: any) => `Lauklio ${field} reikšmė turi buti ${length} ženklų(-o) skaitmuo`,
  dimensions: (field: string, [width, height]: any) => `${field} turi būti ${width} px x ${height} px`,
  email: (field: string) => `Laukelis ${field} turi būti teisingo formato`,
  excluded: (field: string) => `${field} reikšmė nėra leidžiama`,
  ext: (field: string) => `${field} turi būti tinkamas failas`,
  image: (field: string) => `${field} turi būti paveikslėlis`,
  included: (field: string) => `${field} reikšmė nėra leidžiama`,
  ip: (field: string) => `${field} turi būti ip adresas`,
  max: (field: string, [length]: any) => `${field} negali būti ilgesnis nei ${length}`,
  max_value: (field: string, [max]: any) => `${field} turi būti ${max} arba mažiau`,
  mimes: (field: string) => `${field} privalo turėti tinkmą failo tipą`,
  min: (field: string, [length]: any) => `${field} ilgis privalo būti bent ${length}`,
  min_value: (field: string, [min]: any) => `${field} turi būti ${min} arba daugiau`,
  numeric: (field: string) => `${field} turi būti tik skaitmenys`,
  regex: (field: string) => `Laukelio ${field} formatas netinkamas`,
  required: (field: string) => `Laukelis ${field} privalomas`,
  size: (field: string, [size]: any) => `${field} turi būti mažesnis nei ${size}`,
  url: (field: string) => `${field} turi būti internetinis adresas`,
  inList: (field: string) => `Laukelis ${field} privalomas`,
};
