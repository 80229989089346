import { ApolloClient } from "apollo-client";
// import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";

// HTTP connection to the API
// const link = new HttpLink({
//   // You should use an absolute URL here
//   uri: process.env.VUE_APP_HASURA_HTTP || "",
//   headers: ["x-hasura-admin-secret: secret"]
// });

const getHeaders = () => {
  const headers = {
    "x-hasura-admin-secret": "secret",
  };
  // const token = window.localStorage.getItem('apollo-token');
  // if (token) {
  //   headers.Authorization = `Bearer ${token}`;
  // }
  return headers;
};

// Create a WebSocket link:
const link = new WebSocketLink({
  uri: process.env.VUE_APP_HASURA_WS || "",
  options: {
    reconnect: true,
    timeout: 30000,
    connectionParams: () => {
      return { headers: getHeaders() };
    },
  },
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: link,
  cache,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export default apolloProvider;
