
import Vue from "vue";
export default Vue.extend({
  name: "ChatSettings",
  props: {
    status: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
  },
  data() {
    return {
      userStatusMessage: "",
      userStatus: "",
    };
  },
  mounted() {
    this.initState(this.status);
  },
  methods: {
    initState(chatStatus: { message: string; state: string }) {
      if (chatStatus) {
        this.userStatusMessage = chatStatus.message;
        this.userStatus = chatStatus.state;
      }
    },
    saveStatus() {
      this.$emit("save-message", this.userStatusMessage);
    },
    toggleStatus(val: string) {
      this.userStatus = val;
      if (this.status.status !== this.userStatus) {
        this.$emit("save-state", this.userStatus);
      }
    },
  },
  watch: {
    status(val) {
      this.initState(val);
    },
  },
});
