import { Validator } from "vee-validate";

// custom validation rule to check if value is in allowed values array
const isInList = (value: string, { list = [] } = {}): boolean => {
  return Array.isArray(list) && list.find((item: string) => item.toLowerCase() === value.toLowerCase()) !== undefined;
};
const paramNames = ["list"];
Validator.extend(
  "inList",
  {
    getMessage: (field: string) => "Error on " + field,
    validate: isInList,
  },
  {
    paramNames,
  }
);
