import Vue from "vue";

Vue.filter("capitalize", function (value: string) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("currency", (value: string | number) => {
  // logic is ported from the old system
  // alternative ? https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
  let n = value;
  let decimals = 2;
  const thousandsSeparator = "";
  const decimalPoint = ".";

  const toFixedFix = function (n: number, decimals: number) {
    const k = Math.pow(10, decimals);
    return (Math.round(n * k) / k).toString();
  };

  n = !isFinite(+n) ? 0 : +n;
  decimals = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSeparator === "undefined" ? "," : thousandsSeparator;
  const dec = typeof decimalPoint === "undefined" ? "." : decimalPoint;

  let s = decimals > 0 ? toFixedFix(n, decimals) : toFixedFix(Math.round(n), decimals);
  //fix for IE parseFloat(0.55).toFixed(0) = 0;

  const abs = toFixedFix(Math.abs(n), decimals);
  let _, i;

  if (+abs >= 1000) {
    _ = abs.split(/\D/);
    i = _[0].length % 3 || 3;

    _[0] = s.slice(0, i + Number(n < 0)) + _[0].slice(i).replace(/(\d{3})/g, sep + "$1");
    s = _.join(dec);
  } else {
    s = s.replace(".", dec);
  }

  const decPos = s.indexOf(dec);
  if (decimals >= 1 && decPos !== -1 && s.length - decPos - 1 < decimals) {
    s += new Array(decimals - (s.length - decPos - 1)).join("0") + "0";
  } else if (decimals >= 1 && decPos === -1) {
    s += dec + new Array(decimals).join("0") + "0";
  }
  return s;
});

Vue.filter("phone", function (value: string | number) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0) !== "8" ? `8-${value.substr(0, 3)}-${value.substr(3)}` : value;
});

Vue.filter("date", function (value: string | number) {
  if (!value) return "";
  value = value.toString();
  return value.length == 19 ? value.substr(0, 10) : value;
});

Vue.filter("arrayUniqueBy", function (arr: [], key: string) {
  return arr.filter((v, i, a) => a.findIndex((t) => t[key] === v[key]) === i);
});

// for now hardcoding 26 chars
Vue.filter("excerpt", function (value: string) {
  if (!value) return "";
  value = value.toString();
  return value.substring(0, 25);
});

export default {
  capitalize: Vue.filter("capitalize"),
  currency: Vue.filter("currency"),
  phone: Vue.filter("phone"),
  date: Vue.filter("date"),
  arrayUniqueBy: Vue.filter("arrayUniqueBy"),
};
